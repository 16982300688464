import { USER_ROLES } from 'libs/constants';
import { LocalePattern } from 'libs/constants/lang';
import AddEmployeeUserPage from 'pages/Admin/AddEmployeeUserPage';
import AddTechnicalUserPage from 'pages/Admin/AddTechnicalUserPage';
import AdminWorkspacePage from 'pages/Admin/AdminWorkspacePage';
import ConfigurationPage from 'pages/Admin/ConfigurationPage';
import ContactReasonManagementPage from 'pages/Admin/ContactReasonManagement';
import InviteNewUserPage from 'pages/Admin/InviteNewUserPage';
import AdminLoginPage from 'pages/Admin/Login';
import ProductManagementPage from 'pages/Admin/ProductManagementPage';
import PromotionPage from 'pages/Admin/PromotionPage';
import RentalManagement from 'pages/Admin/RentalManagement';
import RoleManagementPage from 'pages/Admin/RoleManagementPage';
import RoleCreatePage from 'pages/Admin/RoleManagementPage/RoleCreate';
import RoleDetailPage from 'pages/Admin/RoleManagementPage/RoleDetailPage';
import RoleEditPage from 'pages/Admin/RoleManagementPage/RoleEdit';
import ServiceCaseManagement from 'pages/Admin/ServicesManagement';
import SupportArea from 'pages/Admin/SupportArea';
import UserManagementPage from 'pages/Admin/UserManagementPage';
import EmployeeDetailsPage from 'pages/Admin/UserManagementPage/EmployeeDetailsPage';
import UserDetailsPage from 'pages/Admin/UserManagementPage/UserDetailsPage';
import TechnicalUserDetailsPages from 'pages/Admin/UserManagementPage/UserDetailsPage/TechnicalUserDetailsPage';
import VoucherManagementPage from 'pages/Admin/VoucherManagement';
import VoucherCreate from 'pages/Admin/VoucherManagement/VoucherCreate';
import VoucherEdit from 'pages/Admin/VoucherManagement/VoucherEdit';
import CallbackPage from 'pages/Callback';
import Cart from 'pages/Cart';
import CheckoutPage from 'pages/CheckoutPage';
import ContactFormPage from 'pages/ContactFormPage/wrapper';
import LandingPage from 'pages/LandingPage';
import LogoutPage from 'pages/Logout';
import MyMachinesPage from 'pages/MyMachinesPage';
import MyWorkspacePage from 'pages/MyWorkspacePage';
import OrderConfirmationPage from 'pages/OrderConfirmationPage';
import OrderDetailsPage from 'pages/OrderPage/OrderDetailsPage';
import OrdersHistoryPage from 'pages/OrderPage/OrdersHistoryPage';
import DataProtection from 'pages/PolicyPage/DataProtection';
import GeneralTermsAndConditions from 'pages/PolicyPage/GeneralTerm';
import Imprint from 'pages/PolicyPage/Imprint';
import ProductComparison from 'pages/ProductComparison';
import ProductDetailPage from 'pages/ProductDetailPage';
import ProductMatchingPage from 'pages/ProductMatchingPage';
import CategoryOverviewPage from 'pages/ProductNavigationPage/CategoryOverviewPage';
import SubCategoryOverviewPage from 'pages/ProductNavigationPage/SubCategoryOverviewPage';
import SubCategoryPage from 'pages/ProductNavigationPage/SubCategoryPage/SubCategoryPage';
import ProfileChangeInvoicePage from 'pages/ProfileChangeInvoicePage';
import ProfilePage from 'pages/ProfilePage';
import PunchoutPage from 'pages/Punchout';
import RegistrationPage from 'pages/RegistrationPage';
import SelfRegistrationConfirmationPage from 'pages/RegistrationPage/SelfRegistrationConfirmationPage';
import SelfRegistrationPage from 'pages/RegistrationPage/SelfRegistrationPage';
import RentalPage from 'pages/Rental';
import RequestServiceConfirmationPage from 'pages/RequestServiceConfirmationPage';
import RequestServicePage from 'pages/RequestServicePage';
import SearchResultsPage from 'pages/SearchResultsPage';
import SearchResultsDetailPage from 'pages/SearchResultsPage/Detail';
import ServicePackagesPage from 'pages/ServicePackagesPage';
import SetPasswordPage from 'pages/SetPasswordPage';
import SparePartsFinder from 'pages/SparePartsFinder';
import TechnicalLoginPage from 'pages/TechnicalLogin';
import WishlistOverview from 'pages/WishlistOverview';
import WishlistDetailPage from 'pages/WishlistPage/DetailPage';

import {
  MODULE_ADMINISTRATION_RIGHTS,
  MODULE_MACHINE_RIGHTS,
  MODULE_ORDER_RIGHTS,
  MODULE_PROFILE_RIGHTS,
  MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS,
  MODULE_SERVICE_RIGHTS,
  MODULE_SHOP_NAVIGATION_RIGHTS,
  MODULE_USER_MANAGEMENT_RIGHTS,
} from '../libs/constants/modulerights';

/**
 * A string containes supported language codes to append to the Url
 * Example:
 * (en|en-GB|de-AT|de-CH|de-DE|fr-BE|fr-CH|nl-BE|nl-NL|pl-PL|ru-RU)
 * https://business.kaercher.com/en/orders
 * https://business.kaercher.com/de-at/my-machines
 */

export const generateRoutes = () => {
  const langCodes = `/:locale(${LocalePattern})?`;

  const publicRoutes = [
    {
      path: '/callback',
      exact: true,
      component: CallbackPage,
    },
    {
      path: '/logout',
      exact: true,
      component: LogoutPage,
    },
    {
      title: 'welcome welcome-subtext',
      path: [`${langCodes}`, '/'],
      exact: true,
      component: LandingPage,
    },
    {
      path: ['/admin-login', `${langCodes}/admin-login`],
      exact: true,
      component: AdminLoginPage,
    },
    {
      path: ['/technical-login', `${langCodes}/technical-login`],
      component: TechnicalLoginPage,
    },
    {
      path: `${langCodes}/data-protection-policy`,
      title: 'pageTitle.dataProtection',
      exact: true,
      pageID: '30',
      component: DataProtection,
    },
    {
      path: `${langCodes}/imprint`,
      title: 'pageTitle.imprint',
      exact: true,
      pageID: '31',
      component: Imprint,
    },
    {
      path: `${langCodes}/general-terms-and-conditions`,
      title: 'pageTitle.generalTerms',
      exact: true,
      pageID: '35',
      component: GeneralTermsAndConditions,
    },
    {
      path: `${langCodes}/self-registration/:userId`,
      exact: true,
      title: 'pageTitle.selfRegistration',
      pageID: '36',
      component: SelfRegistrationPage,
    },
    {
      path: `${langCodes}/self-registration-confirmation`,
      title: 'pageTitle.selfRegistrationConfirmation',
      exact: true,
      pageID: '37',
      component: SelfRegistrationConfirmationPage,
    },
    {
      path: [
        '/registration',
        '/registration/:token',
        '/registration/:token/:language/:countryId',
      ],
      exact: true,
      title: 'pageTitle.registration',
      pageID: '29',
      component: RegistrationPage,
    },
    {
      title: 'welcome welcome-subtext',
      path: ['/punchout', `${langCodes}/punchout`],
      component: PunchoutPage,
    },
  ];

  const privateRoutes = [
    {
      path: `${langCodes}/my-workspace`,
      exact: true,
      title: 'topHeader',
      pageID: '1',
      component: MyWorkspacePage,
    },
    {
      path: `${langCodes}/cart`,
      exact: true,
      title: 'pageTitle.shoppingCart',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.shoppingCart',
          path: '/cart',
        },
      ],
      pageID: '2',
      component: Cart,
      requirePermission: MODULE_SHOP_NAVIGATION_RIGHTS.VIEW_CART,
    },
    {
      path: `${langCodes}/checkout`,
      exact: true,
      title: 'pageTitle.checkoutCart',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.shoppingCart',
          path: '/cart',
        },
        {
          title: 'breadcrumbTitle.checkoutCart',
          path: '/checkout',
        },
      ],
      pageID: '3',
      component: CheckoutPage,
    },
    {
      path: `${langCodes}/confirm-order`,
      exact: true,
      title: 'pageTitle.orderConfirmation',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.orderConfirmation',
          path: '/confirm-order',
        },
      ],
      pageID: '4',
      component: OrderConfirmationPage,
    },
    {
      path: `${langCodes}/orders`,
      exact: true,
      title: 'pageTitle.orderHistory',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.orderHistory',
          path: '/orders',
        },
      ],
      pageID: '5',
      component: OrdersHistoryPage,
      requirePermission: MODULE_ORDER_RIGHTS.VIEW_ORDER_HISTORY,
    },
    {
      path: `${langCodes}/orders/:orderNumber`,
      exact: true,
      title: 'pageTitle.order $1',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.orderHistory',
          path: '/orders',
        },
        {
          title: 'breadcrumbTitle.order $1',
          path: '/orders/$1',
          dynamicTitle: true,
          dynamicPath: true,
        },
      ],
      pageID: '6',
      component: OrderDetailsPage,
      requirePermission: MODULE_ORDER_RIGHTS.VIEW_ORDER_DETAIL,
    },
    {
      path: `${langCodes}/my-machines`,
      exact: true,
      title: 'pageTitle.myMachines',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.myMachines',
          path: '/my-machines',
        },
      ],
      pageID: '7',
      component: MyMachinesPage,
      requirePermission: MODULE_MACHINE_RIGHTS.VIEW_MACHINE_LIST,
    },
    {
      path: `${langCodes}/contact-form`,
      exact: true,
      title: 'pageTitle.contactForm',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.contactForm',
          path: '/contact-form',
        },
      ],
      pageID: '8',
      component: ContactFormPage,
      requirePermission: MODULE_SERVICE_RIGHTS.VIEW_CONTACT_FORM,
    },
    {
      path: `${langCodes}/product/:materialNumber`,
      exact: true,
      title: '$1',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.productCategoryOverview',
          path: '/categories',
        },
        {
          title: '$1',
          path: '/category/$1',
          dynamicTitle: true,
          dynamicPath: true,
        },
        {
          title: '$1',
          path: '/category/$1/subcategory/$2',
          dynamicTitle: true,
          dynamicPath: true,
        },
        {
          title: '$1',
          path: '/category/$1/subcategory/$2/class/$3',
          dynamicTitle: true,
          dynamicPath: true,
        },
        {
          title: '$1',
          path: '/product/$1',
          dynamicTitle: true,
          dynamicPath: true,
        },
      ],
      pageID: '9',
      component: ProductDetailPage,
      requirePermission: MODULE_SHOP_NAVIGATION_RIGHTS.VIEW_PRODUCT_INFORMATION,
    },
    {
      path: [
        `${langCodes}/accessories/product/:materialNumber`,
        `${langCodes}/detergents/product/:materialNumber`,
      ],
      exact: true,
      title: '$1',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.productCategoryOverview',
          path: '/categories',
        },
        {
          title: '$1',
          path: '/categories/$1',
          dynamicTitle: true,
          dynamicPath: true,
        },
        {
          title: '$1',
          path: '/category/$1',
          dynamicTitle: true,
          dynamicPath: true,
        },
        {
          title: '$1',
          path: '/category/$1/subcategory/$2',
          dynamicTitle: true,
          dynamicPath: true,
        },
        {
          title: '$1',
          path: '/category/$1/subcategory/$2/class/$3',
          dynamicTitle: true,
          dynamicPath: true,
        },
        {
          title: '$1',
          path: '/$1/product/$2',
          dynamicTitle: true,
          dynamicPath: true,
        },
      ],
      pageID: '9.1',
      component: ProductDetailPage,
      requirePermission: MODULE_SHOP_NAVIGATION_RIGHTS.VIEW_PRODUCT_INFORMATION,
    },
    {
      path: `${langCodes}/product/:materialNumber/:type`,
      exact: true,
      title: 'pageTitle.productMatching',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.searchResults',
          path: '/product/$1/$2',
          dynamicPath: true,
        },
      ],
      pageID: '10',
      component: ProductMatchingPage,
    },
    {
      path: `${langCodes}/categories`,
      exact: true,
      title: 'pageTitle.productCategoryOverview',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.productCategoryOverview',
          path: '/categories',
        },
      ],
      pageID: '11',
      component: CategoryOverviewPage,
    },
    {
      path: `${langCodes}/categories/:type`,
      exact: true,
      title: '$1',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.productCategoryOverview',
          path: '/categories',
        },
        {
          title: '$1',
          path: '/categories/$1',
          dynamicTitle: true,
          dynamicPath: true,
        },
      ],
      pageID: '11.1',
      component: CategoryOverviewPage,
    },
    {
      path: `${langCodes}/category/:categoryId`,
      exact: true,
      title: '$1',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.productCategoryOverview',
          path: '/categories',
        },
        {
          title: '$1',
          path: '/category/$1',
          dynamicTitle: true,
          dynamicPath: true,
        },
      ],
      pageID: '12',
      component: SubCategoryOverviewPage,
    },
    {
      path: [
        `${langCodes}/accessories/category/:categoryId`,
        `${langCodes}/detergents/category/:categoryId`,
      ],
      exact: true,
      title: '$1',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.productCategoryOverview',
          path: '/categories',
        },
        {
          title: '$1',
          path: '/categories/$1',
          dynamicTitle: true,
          dynamicPath: true,
        },
        {
          title: '$1',
          path: '/$1/category/$2',
          dynamicTitle: true,
          dynamicPath: true,
        },
      ],
      pageID: '12',
      component: SubCategoryOverviewPage,
    },
    {
      path: [
        `${langCodes}/category/:categoryId/subcategory/:subCategoryId/class/:classTypeId`,
        `${langCodes}/category/:categoryId/subcategory/:subCategoryId`,
      ],
      exact: true,
      title: '$1',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.productCategoryOverview',
          path: '/categories',
        },
        {
          title: '$1',
          path: '/category/$1',
          dynamicTitle: true,
          dynamicPath: true,
        },
        {
          title: '$1',
          path: '/category/$1/subcategory/$2',
          dynamicTitle: true,
          dynamicPath: true,
        },
      ],
      pageID: '13',
      component: SubCategoryPage,
    },
    {
      path: [
        `${langCodes}/accessories/category/:categoryId/subcategory/:subCategoryId/class/:classTypeId`,
        `${langCodes}/accessories/category/:categoryId/subcategory/:subCategoryId`,
        `${langCodes}/detergents/category/:categoryId/subcategory/:subCategoryId/class/:classTypeId`,
        `${langCodes}/detergents/category/:categoryId/subcategory/:subCategoryId`,
      ],
      exact: true,
      title: '$1',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.productCategoryOverview',
          path: '/categories',
        },
        {
          title: '$1',
          path: '/categories/$1',
          dynamicTitle: true,
          dynamicPath: true,
        },
        {
          title: '$1',
          path: '/$1/category/$2',
          dynamicTitle: true,
          dynamicPath: true,
        },
        {
          title: '$1',
          path: '/$1/category/$2/subcategory/$3',
          dynamicTitle: true,
          dynamicPath: true,
        },
      ],
      pageID: '13',
      component: SubCategoryPage,
    },
    {
      path: [
        `${langCodes}/search-results/:searchParam+`,
        `${langCodes}/search-results/`,
      ],
      exact: true,
      title: 'pageTitle.searchResults',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.searchResults',
          path: '/search-results/$1',
          dynamicPath: true,
        },
      ],
      pageID: '14',
      component: SearchResultsPage,
    },
    {
      path: `${langCodes}/search-detail/:type/:searchParam+`,
      exact: true,
      title: 'pageTitle.searchResults',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.searchResults',
          path: '/search-detail/$1/$2',
          dynamicPath: true,
        },
      ],
      pageID: '15',
      component: SearchResultsDetailPage,
    },
    {
      path: `${langCodes}/profile`,
      exact: true,
      title: 'pageTitle.myProfile',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.myProfile',
          path: '/profile',
        },
      ],
      pageID: '16',
      component: ProfilePage,
      requirePermission: MODULE_PROFILE_RIGHTS.VIEW_PROFILE,
    },
    {
      path: `${langCodes}/profile/change-password`,
      exact: true,
      title: 'pageTitle.changePassword',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.myProfile',
          path: '/profile',
        },
        {
          title: 'breadcrumbTitle.changePassword',
          path: '/profile/change-password',
        },
      ],
      pageID: '17',
      component: SetPasswordPage,
    },
    {
      path: `${langCodes}/profile/change-invoice-address`,
      exact: true,
      title: 'changeInvoiceAddress.title',
      pageID: '18',
      component: ProfileChangeInvoicePage,
    },
    {
      path: `${langCodes}/product-comparison`,
      title: 'pageTitle.productComparison',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.productComparison',
          path: '/product-comparison',
        },
      ],
      pageID: '19',
      component: ProductComparison,
    },
    {
      path: `${langCodes}/request-services`,
      title: 'pageTitle.requestServices',
      exact: true,
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.requestServices',
          path: '/request-services',
        },
      ],
      pageID: '20',
      component: RequestServicePage,
    },
    {
      path: `${langCodes}/request-confirmation`,
      title: 'pageTitle.confirmService',
      exact: true,
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.services',
          path: '/request-services/repair',
        },
        {
          title: 'breadcrumbTitle.createService',
          path: '/request-services/repair',
        },
      ],
      pageID: '21',
      component: RequestServiceConfirmationPage,
    },
    {
      path: `${langCodes}/service/service-packages`,
      title: 'pageTitle.servicePackages',
      exact: true,
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.services',
          path: undefined,
        },
        {
          title: 'breadcrumbTitle.servicePackages',
          path: '/service/service-packages',
        },
      ],
      pageID: '22',
      component: ServicePackagesPage,
      requirePermission:
        MODULE_SHOP_NAVIGATION_RIGHTS.VIEW_SERVICE_PACKAGE_INFORMATION,
    },
    {
      exact: true,
      title: 'pageTitle.productManagement',
      path: `${langCodes}/product-management`,
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.productManagement',
          path: '/product-management',
        },
      ],
      pageID: '44',
      component: ProductManagementPage,
      allowedRoles: [USER_ROLES.KaercherAdmin],
      requirePermission:
        MODULE_ADMINISTRATION_RIGHTS.VIEW_PRODUCT_PORFOLIO_MANAGEMENT,
    },
    {
      exact: true,
      path: [`${langCodes}/spare-parts`],
      title: 'pageTitle.sparePartsFinder',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'pageTitle.sparePartsFinder',
          path: '/spare-parts',
        },
      ],
      pageID: '45',
      component: SparePartsFinder,
      requirePermission: MODULE_SHOP_NAVIGATION_RIGHTS.OPEN_SPARE_PART_SHOP,
    },
    {
      exact: true,
      path: [`${langCodes}/wishlists`],
      title: 'pageTitle.wishlistOverview',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'pageTitle.wishlistOverview',
          path: '/wishlists',
        },
      ],
      pageID: '46',
      component: WishlistOverview,
    },
    {
      exact: true,
      path: [`${langCodes}/wishlist/:wishlistId/owner/:wishlistOwnerId`],
      title: 'pageTitle.wishlistDetail',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'pageTitle.wishlistOverview',
          path: '/wishlists',
        },
        {
          title: 'pageTitle.wishlistDetail',
          path: '/wishlist/:wishlistId/owner/:wishlistOwnerId',
        },
      ],
      pageID: '47',
      component: WishlistDetailPage,
    },
    {
      exact: true,
      path: [`${langCodes}/support-area`],
      title: 'pageTitle.supportArea',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'pageTitle.supportArea',
          path: '/support-area',
        },
      ],
      pageID: '48',
      component: SupportArea,
      allowedRoles: [USER_ROLES.KaercherAdmin],
      requirePermission: MODULE_ADMINISTRATION_RIGHTS.SUPPORT_AREA,
    },
    {
      path: `${langCodes}/admin-workspace`,
      exact: true,
      title: 'pageTitle.adminWorkspace',
      pageID: '23',
      component: AdminWorkspacePage,
      allowedRoles: [USER_ROLES.KaercherAdmin],
      requirePermission: MODULE_ADMINISTRATION_RIGHTS.VIEW_ADMIN_AREA,
    },
    {
      path: `${langCodes}/voucher-management`,
      exact: true,
      title: 'pageTitle.voucherManagement',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.voucherManagement',
          path: '/voucher-management',
        },
      ],
      pageID: '24',
      component: VoucherManagementPage,
      allowedRoles: [USER_ROLES.KaercherAdmin],
      requirePermission:
        MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS.VIEW_VOUCHER,
    },
    {
      path: `${langCodes}/promotion-management`,
      title: 'pageTitle.promotionManagement',
      exact: true,
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.promotionManagement',
          path: '/promotion-management',
        },
      ],
      pageID: '25',
      component: PromotionPage,
      allowedRoles: [USER_ROLES.KaercherAdmin],
      requirePermission:
        MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS.UPDATE_PROMOTION,
    },
    {
      path: [
        `${langCodes}/user-management`,
        `${langCodes}/user-management/:userType(employee|user|technical)`,
      ],
      exact: true,
      title: 'pageTitle.userManagement',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.userManagement',
          path: '/user-management',
        },
      ],
      pageID: '26',
      component: UserManagementPage,
      allowedRoles: [USER_ROLES.KaercherAdmin],
    },
    {
      path: [`${langCodes}/user-management/:userType(technical)/:userId`],
      title: '$1',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.userManagement',
          path: '/user-management',
        },
        {
          title: '$1',
          path: '/user-management/$1',
          dynamicTitle: true,
          dynamicPath: true,
        },
      ],
      pageID: '27',
      component: TechnicalUserDetailsPages,
      allowedRoles: [USER_ROLES.KaercherAdmin],
      requirePermission: MODULE_USER_MANAGEMENT_RIGHTS.VIEW_USER_DETAIL,
    },
    {
      path: [
        `${langCodes}/user-management/invitation/:userId`,
        `${langCodes}/user-management/:userId`,
      ],
      title: '$1',
      exact: true,
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.userManagement',
          path: '/user-management',
        },
        {
          title: '$1',
          path: '/user-management/$1',
          dynamicTitle: true,
          dynamicPath: true,
        },
      ],
      pageID: '27',
      component: UserDetailsPage,
      allowedRoles: [USER_ROLES.KaercherAdmin],
      requirePermission: MODULE_USER_MANAGEMENT_RIGHTS.VIEW_USER_DETAIL,
    },
    {
      path: [`${langCodes}/user-management/:userType(employee)/:userId`],
      exact: true,
      title: '$1',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.userManagement',
          path: '/user-management',
        },
        {
          title: '$1',
          path: '/user-management/$1',
          dynamicTitle: true,
          dynamicPath: true,
        },
      ],
      pageID: '27',
      component: EmployeeDetailsPage,
      allowedRoles: [USER_ROLES.KaercherAdmin],
      requirePermission: MODULE_USER_MANAGEMENT_RIGHTS.VIEW_USER_DETAIL,
    },
    {
      path: `${langCodes}/user-invitation`,
      title: 'pageTitle.inviteNewUser',
      exact: true,
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'pageTitle.userManagement',
          path: '/user-management',
        },
        {
          title: 'pageTitle.inviteNewUser',
          path: '/user-invitation',
        },
      ],
      pageID: '28',
      component: InviteNewUserPage,
      allowedRoles: [USER_ROLES.KaercherAdmin],
    },
    {
      path: `${langCodes}/contact-reasons`,
      title: 'pageTitle.contactReasons',
      exact: true,
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.contactReasons',
          path: '/contact-reasons',
        },
      ],
      pageID: '33',
      component: ContactReasonManagementPage,
      allowedRoles: [USER_ROLES.KaercherAdmin],
      requirePermission: MODULE_ADMINISTRATION_RIGHTS.CRUD_CONTACT_REASON,
    },
    {
      path: `${langCodes}/configuration-management`,
      title: 'pageTitle.configurations',
      exact: true,
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.configurations',
          path: '/configuration-management',
        },
      ],
      pageID: '32',
      component: ConfigurationPage,
      allowedRoles: [USER_ROLES.KaercherAdmin],
      requirePermission: MODULE_ADMINISTRATION_RIGHTS.VIEW_CONFIGURATION,
    },
    {
      path: `${langCodes}/service-management`,
      title: 'pageTitle.serviceManagement',
      exact: true,
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.serviceManagement',
          path: '/service-management',
        },
      ],
      pageID: '34',
      component: ServiceCaseManagement,
      allowedRoles: [USER_ROLES.KaercherAdmin],
    },
    {
      path: [`${langCodes}/add-employee-user`],
      exact: true,
      title: 'pageTitle.addUser',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'pageTitle.userManagement',
          path: '/user-management',
        },
        {
          title: 'pageTitle.addUser',
          path: '/add-employee-user',
        },
      ],
      pageID: '38',
      component: AddEmployeeUserPage,
      allowedRoles: [USER_ROLES.KaercherAdmin],
    },
    {
      path: [`${langCodes}/add-technical-user`],
      exact: true,
      title: 'pageTitle.addUser',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'pageTitle.userManagement',
          path: '/user-management',
        },
        {
          title: 'pageTitle.addUser',
          path: '/add-technical-user',
        },
      ],
      pageID: '39',
      component: AddTechnicalUserPage,
      allowedRoles: [USER_ROLES.KaercherAdmin],
    },
    {
      path: [`${langCodes}/roles-management`],
      exact: true,
      title: 'pageTitle.roleManagement',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'pageTitle.roleManagement',
          path: '/roles-management',
        },
      ],
      pageID: '40',
      component: RoleManagementPage,
      allowedRoles: [USER_ROLES.KaercherAdmin],
    },
    {
      path: [`${langCodes}/roles-management/create-role`],
      exact: true,
      title: 'pageTitle.createRole',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'pageTitle.createRole',
          path: '/roles-management/create-role',
        },
      ],
      pageID: '41',
      component: RoleCreatePage,
      allowedRoles: [USER_ROLES.KaercherAdmin],
    },
    {
      path: [`${langCodes}/roles-management/:roleId`],
      exact: true,
      title: 'pageTitle.roleDetails',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'pageTitle.roleManagement',
          path: '/roles-management',
        },
        {
          title: '$1',
          path: '/roles-management/$1',
          dynamicTitle: true,
          dynamicPath: true,
        },
      ],
      pageID: '42',
      component: RoleDetailPage,
      allowedRoles: [USER_ROLES.KaercherAdmin],
    },
    {
      exact: true,
      path: [`${langCodes}/roles-management/edit/:roleId`],
      title: 'pageTitle.roleManagement',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'pageTitle.roleManagement',
          path: '/roles-management',
        },
        {
          title: '$1',
          path: '/roles-management/$1',
          dynamicTitle: true,
          dynamicPath: true,
        },
      ],
      pageID: '43',
      component: RoleEditPage,
      allowedRoles: [USER_ROLES.KaercherAdmin],
    },
    {
      path: `${langCodes}/voucher`,
      exact: true,
      title: 'pageTitle.voucherManagement',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.voucherManagement',
          path: '/voucher-management',
        },
      ],
      pageID: '49',
      component: VoucherCreate,
      allowedRoles: [USER_ROLES.KaercherAdmin],
    },
    {
      path: `${langCodes}/voucher/:voucherId`,
      exact: true,
      title: 'pageTitle.voucherManagement',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.voucherManagement',
          path: '/voucher-management',
        },
      ],
      pageID: '50',
      component: VoucherEdit,
      allowedRoles: [USER_ROLES.KaercherAdmin],
    },
    {
      path: `${langCodes}/rental`,
      exact: true,
      title: 'pageTitle.rental',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.rental',
          path: '/rental',
        },
      ],
      pageID: '51',
      component: RentalPage,
    },
    {
      path: `${langCodes}/rental-management`,
      exact: true,
      title: 'pageTitle.rentalManagement',
      breadcrumbs: [
        {
          title: 'breadcrumbTitle.home',
          path: '/',
        },
        {
          title: 'breadcrumbTitle.rentalManagement',
          path: '/rental-management',
        },
      ],
      pageID: '52',
      component: RentalManagement,
      allowedRoles: [USER_ROLES.KaercherAdmin],
    },
  ];

  return { publicRoutes, privateRoutes };
};
