import './styles.scss';

import { Form, Input, Radio } from 'antd';
import clsx from 'clsx';
import useProvinces from 'hooks/useAddress';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserCountry } from 'store/selectors/userSelector';

import NewDeliveryAddress from '../NewDeliveryAddress';

const MyDeliveryAddressHidden = ({ isShowProvince = false }) => {
  return (
    <>
      <Form.Item name="myAddrPartnerNumber" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="myAddrName" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="myAddrStreet" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="myAddrStreet4" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="myAddrName2" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="myAddrHouseNumber" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="myAddrZip" hidden>
        <Input />
      </Form.Item>
      {isShowProvince && (
        <Form.Item name="myAddrRegion" hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item name="myAddrCity" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="myAddrCountry" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="myAddrPhoneNumber" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="myAddrEmail" hidden>
        <Input />
      </Form.Item>
    </>
  );
};
const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '22px',
};

const DeliveryAddressMobile = ({ deliveryAddresses, form }) => {
  const { t } = useTranslation();
  const [isAddNewAddress, setIsAddNewAddress] = useState(false);
  const country = useSelector(selectUserCountry);
  const { getAddressWithProvince, isShowProvince } = useProvinces();

  const handleSelectMyAddress = (selectedId) => {
    const address = deliveryAddresses[selectedId];

    setIsAddNewAddress(false);
    form.setFieldsValue({
      myAddrPartnerNumber: address?.partnerNumber,
      myAddrName: address?.name,
      myAddrName2: address?.name2,
      myAddrStreet: address?.street,
      myAddrStreet4: address?.street4,
      myAddrHouseNumber: address?.houseNumber,
      myAddrZip: address?.zip,
      myAddrRegion: address?.region,
      myAddrCity: address?.city,
      myAddrCountry: address?.country,
      myAddrPhoneNumber: address?.phoneNumber,
      myAddrEmail: address?.email,
      currentDeliveryAddr: 1,
    });
  };

  const handleSelectAddNewAddress = () => {
    setIsAddNewAddress(true);
    form.setFieldsValue({
      newAddrPartnerNumber: '',
      newAddrName: '',
      newAddrName2: '',
      newAddrStreet: '',
      newAddrStreet4: '',
      newAddrHouseNumber: '',
      newAddrZip: '',
      newAddrRegion: '',
      newAddrCity: '',
      newAddrPhoneNumber: '',
      newAddrCountry: country,
      newAddrEmail: '',
      currentDeliveryAddr: 2,
    });
  };

  return (
    <Radio.Group
      className="custom-input"
      style={{ width: '100%' }}
      defaultValue={0}
      onChange={(e) => {
        const { value } = e.target;

        if (value === 'newAddress') {
          handleSelectAddNewAddress();
        } else {
          handleSelectMyAddress(value);
        }
      }}
    >
      {deliveryAddresses?.map((data, index) => {
        const address = getAddressWithProvince(data);
        return (
          <Radio
            key={data.partnerNumber}
            className="delivery-information-radio border-bottom"
            value={index}
          >
            <div
              className={clsx(
                'delivery-information',
                isAddNewAddress && 'disabled'
              )}
            >
              <span className="delivery-information__name font-weight-bold">
                {address.name}
              </span>
              <span className="delivery-information__address">
                {address.address}
              </span>
              <span className="delivery-information__address">
                {address.street4}
              </span>
              <span className="delivery-information__zip">
                {address.location}
              </span>
              <span className="delivery-information__country font-weight-bold">
                {data.country}
              </span>
            </div>
          </Radio>
        );
      })}
      <Radio
        className="delivery-information-radio border-bottom"
        value="newAddress"
        style={radioStyle}
      >
        <span className="mt-6 mb-4 delivery__option__title">
          {t('form.label.newAddress')}
        </span>
        {isAddNewAddress ? (
          <NewDeliveryAddress form={form} />
        ) : (
          <MyDeliveryAddressHidden isShowProvince={isShowProvince} />
        )}
      </Radio>
    </Radio.Group>
  );
};

DeliveryAddressMobile.propTypes = {
  deliveryAddresses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  form: PropTypes.shape({ setFieldsValue: PropTypes.func }).isRequired,
};

export default React.memo(DeliveryAddressMobile);
