import './styles.scss';

import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { ReactComponent as AlertIconSVG } from 'assets/icons/alerticon.svg';
import greenCheckIconImage from 'assets/img/green-check-icon.webp';
import itemNumberExampleImage from 'assets/img/item-number-example.webp';
import moreInfoIconImage from 'assets/img/more-info-icon.webp';
import serialNumberExampleImage from 'assets/img/serial-number-example.webp';
import clsx from 'clsx';
import B2becTranslation from 'components/B2becTranslation';
import ImageWithFallBack from 'components/ImageWithFallback';
import DangerouslySetInnerElement from 'components/SpecialSymbolElement';
import useAsync from 'hooks/useAsync';
import useDebounce from 'hooks/useDebounce';
import useDeviceDetect from 'hooks/useDeviceDetect';
import { ASYNC_STATUS, INVALID_TYPES } from 'libs/constants';
import { validateSerialNumber } from 'libs/utils/formatSerialNumber';
import { formatMaterialID } from 'libs/utils/material-number';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ProductService, serviceCaseService } from 'services';
import { selectServiceRepairPageByLanguage } from 'store/selectors/configSelector';
import { getSelectedMachineData } from 'store/selectors/serviceCaseSelector';
import { selectUserCountryId } from 'store/selectors/userSelector';
import {
  resetSelectedMachineInfo,
  setSelectedMachineInfo,
} from 'store/slices/serviceCaseSlice';

const SERIAL_NUMBER_VALIDATE_MESSAGE = {
  WRONG_SERIAL_NUMBER: 'service.selectMachine.serialNumber.wrongFormat',
  WRONG_SERIAL_NUMBER_WITH_LETTER:
    'service.selectMachine.serialNumber.wrongFormatWithLeter',
};

const validateExcludedMachine = (materialNumber, countryId) => {
  return serviceCaseService.validateMaterialNumber(materialNumber, countryId);
};

const SelectNewMachineBlock = (props) => {
  const {
    selectedMachineData: { isExcludedMachine },
  } = props;

  const { isMobile } = useDeviceDetect();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isShowModal, setShowInfoModal] = useState(false);

  const selectedMachineData = useSelector(getSelectedMachineData);
  const countryId = useSelector(selectUserCountryId);

  const [serialNumber, setSerialNumber] = useState(null);
  const [serialNumberMsg, setSerialNumberMsg] = useState(null);
  const [isSerialNumberInfoShown, setIsSerialNumberInfoShown] = useState(false);

  const [itemNumber, setItemNumber] = useState(null);
  const [itemNumberMsg, setItemNumberMsg] = useState(null);
  const [isItemNumberInfoShown, setIsItemNumberInfoShown] = useState(false);

  const [itemName, setItemName] = useState(null);
  const [itemImg, setItemImg] = useState(null);

  const debouncedGetBasicProduct = useDebounce(itemNumber, 200);

  const submitGetBasicProduct = useCallback((materialNumber) => {
    return ProductService.getBasicProductInfo(materialNumber);
  }, []);

  const {
    execute: executeExcludedMachineValidation,
    status: excludedMachineValidationStatus,
    error: excludedMachineValidationError,
  } = useAsync(validateExcludedMachine, false);

  const {
    execute: executeGetBasicProductInfo,
    status: getBasicProductStatus,
    error: getBasicProductError,
  } = useAsync(submitGetBasicProduct, false);

  const clearData = useCallback(() => {
    // clear item number
    setItemNumber(null);
    setItemNumberMsg(null);
    setIsItemNumberInfoShown(false);
    // clear item info
    setItemName(null);
    setItemImg(null);
    // clear serial number
    setSerialNumber(null);
    setSerialNumberMsg(null);
    setIsSerialNumberInfoShown(false);
  }, []);

  // when changing service, reset the local state
  useEffect(() => {
    if (
      selectedMachineData?.isChangedService &&
      selectedMachineData?.serviceEntry !== null
    ) {
      clearData();
    }
  }, [
    clearData,
    selectedMachineData.isChangedService,
    selectedMachineData.serviceEntry,
  ]);

  useEffect(() => {
    if (getBasicProductStatus === ASYNC_STATUS.PENDING) {
      setItemNumberMsg(t('form.placeHolder.loading'));
      setItemName(null);
      setItemImg(null);
    }
    if (getBasicProductError) {
      setItemNumberMsg(t('service.selectMachine.itemNumber.notExisted'));
      setItemName(null);
      setItemImg(null);
      // setMachineInformation({});
      dispatch(resetSelectedMachineInfo());
    }
  }, [dispatch, getBasicProductStatus, getBasicProductError, t]);

  useEffect(() => {
    if (excludedMachineValidationStatus === ASYNC_STATUS.ERROR) {
      if (
        excludedMachineValidationError?.response?.data ===
        INVALID_TYPES.EXCLUDED
      ) {
        dispatch(
          setSelectedMachineInfo({
            isExcludedMachine: true,
            validateStatus: ASYNC_STATUS.ERROR,
          })
        );
      }
    } else {
      dispatch(
        setSelectedMachineInfo({
          isExcludedMachine: false,
          validateStatus: excludedMachineValidationStatus,
        })
      );
    }
  }, [
    dispatch,
    excludedMachineValidationStatus,
    excludedMachineValidationError,
  ]);

  const onSerialNumberChange = async (e) => {
    const value = e.target.value.trim();
    try {
      const serial = await validateSerialNumber(value);
      dispatch(setSelectedMachineInfo({ serialNumber: serial }));
      setSerialNumberMsg(null);
      setSerialNumber(serial);
    } catch (error) {
      const errorMessageKey = SERIAL_NUMBER_VALIDATE_MESSAGE[error];
      setSerialNumberMsg(t(errorMessageKey));
    }
  };

  const handleInputValue = (str) => {
    if (str.length <= 8 && RegExp('[0-9]{8}').test(str)) {
      const formattedStr = formatMaterialID(str);
      return formattedStr;
    }
    return str;
  };

  const isItemNumberValid = useCallback((str) => {
    return (
      str.length === 11 &&
      RegExp('^([0-9]{1})\\.([0-9]{3})-([0-9]{3})\\.([0-9]{1})').test(str)
    );
  }, []);

  const getAndValidateProduct = useCallback(
    async (_materialNumber) => {
      const getProduct = executeGetBasicProductInfo(_materialNumber).then(
        ({ response }) => {
          if (response?.data) {
            const { materialNumber, materialName, pictureUrl } = response?.data;
            setItemName(materialName);
            setItemImg(pictureUrl);
            setItemNumberMsg(null);

            dispatch(
              setSelectedMachineInfo({
                id: materialNumber,
                name: materialName,
                image: pictureUrl,
              })
            );
          }
        }
      );

      const validateProduct = executeExcludedMachineValidation(
        _materialNumber,
        countryId
      );

      await Promise.all([getProduct, validateProduct]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  useEffect(() => {
    if (debouncedGetBasicProduct) {
      if (debouncedGetBasicProduct.length <= 11) {
        if (isItemNumberValid(debouncedGetBasicProduct)) {
          // run promise parallely
          getAndValidateProduct(debouncedGetBasicProduct);
        } else {
          setItemNumberMsg(t('errors.stringFormat'));
          setItemName(null);
          setItemImg(null);
        }
      } else {
        setItemNumberMsg(t('errors.maxInputExceeded'));
        setItemName(null);
        setItemImg(null);
      }
    } else {
      clearData();

      dispatch(resetSelectedMachineInfo());
    }
  }, [
    debouncedGetBasicProduct,
    getAndValidateProduct,
    t,
    isItemNumberValid,
    dispatch,
    clearData,
  ]);

  const renderItemNumberBlock = () => {
    const renderItemNumberGreenCheck =
      itemNumberMsg == null &&
      itemNumber?.length > 0 &&
      isItemNumberValid(itemNumber) ? (
        <div className="select-machine__left__serial-number__green-check">
          <ImageWithFallBack src={greenCheckIconImage} />
        </div>
      ) : null;
    return (
      <div className="select-machine__left__item-number">
        <span className="select-machine__label">
          {t('service.selectMachine.itemNumber.label')}
        </span>
        <div className="select-machine__left__item-number__wrapper">
          <Input
            onChange={(e) => {
              const value = e.target.value.trim();
              const re = /^[\d, \\., -]+$/;
              if (value !== '' && re.test(value)) {
                const formattedValue = handleInputValue(value);
                setItemNumber(formattedValue);
              } else {
                setItemNumber(value.replace(/[^\d, \\., -]/, ''));
              }
            }}
            placeholder={t('service.selectMachine.itemNumber.placeHolder')}
            className="custom-input select-machine__left__item-number__input"
            autoComplete="off"
            value={itemNumber}
          />

          {excludedMachineValidationStatus === ASYNC_STATUS.PENDING ? (
            <div className="select-machine__left__serial-number__alert">
              <LoadingOutlined />
            </div>
          ) : isExcludedMachine ? (
            <div className="select-machine__left__serial-number__alert">
              <AlertIconSVG />
            </div>
          ) : (
            renderItemNumberGreenCheck
          )}
          <Button
            className="select-machine__left__more-info-button"
            onClick={() => {
              setIsItemNumberInfoShown(!isItemNumberInfoShown);
            }}
          >
            <ImageWithFallBack
              className="select-machine__more-info"
              src={moreInfoIconImage}
            />
          </Button>
        </div>
        <p className="select-machine__error">{itemNumberMsg}</p>
      </div>
    );
  };

  const renderExcludedMachineInfo = useCallback(() => {
    const LinkToExternalPage = ({ children }) => {
      const serviceRepairPage = useSelector(selectServiceRepairPageByLanguage);

      return (
        <a
          className="select-machine__excluded-machine__redirect-link"
          href={serviceRepairPage}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    };

    LinkToExternalPage.propTypes = {
      children: PropTypes.arrayOf(PropTypes.string),
    };

    LinkToExternalPage.defaultProps = {
      children: [],
    };

    const renderConditions =
      isExcludedMachine &&
      excludedMachineValidationError?.response?.data === INVALID_TYPES.EXCLUDED;

    return (
      renderConditions && (
        <div className="select-machine__excluded-machine__messages">
          <B2becTranslation
            value="service.selectMachine.excludedMachineMessages"
            isComponent
            htmlTags={[
              <div key="conditionEmpty" />,
              <LinkToExternalPage key="conditionExternalPage" />,
            ]}
          />
        </div>
      )
    );
  }, [isExcludedMachine, excludedMachineValidationError]);

  const renderNameBlock = () => {
    return (
      <div className="select-machine__left__name">
        <span className="select-machine__label">
          {t('service.selectMachine.generateName.label')}
        </span>
        {isMobile && itemImg ? (
          <div className="select-machine__left__name__block">
            <ImageWithFallBack
              src={itemImg}
              alt={itemName}
              width="50px"
              height="50px"
            />
            <span>{itemName}</span>
          </div>
        ) : (
          <Input
            type="text"
            className="custom-input select-machine__left__name__input"
            disabled
            value={itemName}
          />
        )}
      </div>
    );
  };

  const renderSerialNumberBlock = () => {
    const renderSerialNumberGreenCheck =
      serialNumberMsg == null && serialNumber?.length > 0 ? (
        <div className="select-machine__left__serial-number__green-check">
          <ImageWithFallBack src={greenCheckIconImage} />
        </div>
      ) : null;
    return (
      <div className="select-machine__left__serial-number">
        <span className="select-machine__label">
          {t('service.selectMachine.serialNumber.label')}
        </span>
        <div className="select-machine__left__serial-number__wrapper">
          <Form.Item
            noStyle
            name={['serialNumber']}
            rules={[
              {
                validator: (_, value) => {
                  return validateSerialNumber(
                    value,
                    t('service.selectMachine.serialNumber.wrongFormat')
                  );
                },
              },
            ]}
          >
            <Input
              onChange={onSerialNumberChange}
              placeholder={t('service.selectMachine.serialNumber.placeHolder')}
              className="custom-input select-machine__left__serial-number__input"
              autoComplete="off"
            />
          </Form.Item>
          {renderSerialNumberGreenCheck}
          <Button
            className="select-machine__left__more-info-button"
            onClick={() => {
              setIsSerialNumberInfoShown(!isSerialNumberInfoShown);
            }}
          >
            <ImageWithFallBack
              className="select-machine__more-info"
              src={moreInfoIconImage}
            />
          </Button>
        </div>
        <p className="select-machine__error">{serialNumberMsg}</p>
        {!isMobile && (
          <div className="select-machine__left__image">
            <img src={itemImg} alt={itemName} style={{ width: '100%' }} />
          </div>
        )}
      </div>
    );
  };

  const renderLeftBlock = () => {
    return (
      <>
        {renderItemNumberBlock()}
        {isMobile && renderExcludedMachineInfo()}
        {renderNameBlock()}
        {renderSerialNumberBlock()}
      </>
    );
  };

  const renderItemNumberInfo = () => {
    return (
      <div
        className={clsx('select-machine__right__block', {
          'select-machine__right__block--visible': isItemNumberInfoShown,
        })}
      >
        <div className="select-machine__right__image">
          <ImageWithFallBack src={itemNumberExampleImage} />
        </div>
        <div className="select-machine__right__main-content select-machine__right__content__description">
          <span style={{ marginBottom: 30, display: 'block' }}>
            {t('service.selectMachine.itemNumberInfo.title')}
          </span>
          <p>{t('service.selectMachine.itemNumberInfo.description')}</p>
        </div>
      </div>
    );
  };

  const renderSerialNumberInfo = () => {
    return (
      <div
        className={clsx('select-machine__right__block', {
          'select-machine__right__block--visible': isSerialNumberInfoShown,
        })}
      >
        <div className="select-machine__right__image">
          <ImageWithFallBack src={serialNumberExampleImage} />
        </div>
        <div className="select-machine__right__main-content select-machine__right__content__description">
          <span style={{ marginBottom: 30, display: 'block' }}>
            {t('service.selectMachine.serialNumberInfo.title')}
          </span>
          <p>{t('service.selectMachine.serialNumberInfo.description')}</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (isMobile && (isItemNumberInfoShown || isSerialNumberInfoShown)) {
      setShowInfoModal(true);
    }
  }, [isMobile, isItemNumberInfoShown, isSerialNumberInfoShown]);

  const renderInstructionModal = useCallback(
    ({ modalTitle, modalContent, imgSrc }) => {
      const onCloseModal = () => {
        // close modal
        setShowInfoModal(false);
        // because the info block on desktop is shown through the toggle feature
        // on mobile, close the modal is not trigger the toggle again
        // need to set it manually
        setIsSerialNumberInfoShown(false);
        setIsItemNumberInfoShown(false);
      };

      return (
        <Modal
          title={modalTitle}
          visible={isShowModal}
          onCancel={onCloseModal}
          footer={null}
          wrapClassName="info-modal--wrapper"
        >
          <DangerouslySetInnerElement rawHTML={modalContent} />
          <div>
            <ImageWithFallBack src={imgSrc} />
          </div>
        </Modal>
      );
    },
    [isShowModal]
  );

  const renderRightBlock = () => {
    return (
      <div className="instruction ">
        {!isMobile && renderExcludedMachineInfo()}
        {isMobile ? (
          <>
            {isItemNumberInfoShown &&
              renderInstructionModal({
                modalTitle: t('service.selectMachine.itemNumberInfo.title'),
                modalContent: t(
                  'service.selectMachine.itemNumberInfo.description'
                ),
                imgSrc: itemNumberExampleImage,
              })}
            {isSerialNumberInfoShown &&
              renderInstructionModal({
                modalTitle: t('service.selectMachine.serialNumberInfo.title'),
                modalContent: t(
                  'service.selectMachine.serialNumberInfo.description'
                ),
                imgSrc: serialNumberExampleImage,
              })}
          </>
        ) : (
          <>
            {renderItemNumberInfo()}
            {renderSerialNumberInfo()}
          </>
        )}
      </div>
    );
  };

  return (
    <div className="select-machine__wrapper">
      <span className="select-machine__description">
        {t('service.selectMachine.findMachineLabel')}
      </span>
      <Row gutter={[100, 0]}>
        <Col lg={10} xs={24} className="select-machine__left">
          {renderLeftBlock()}
        </Col>
        <Col lg={14} xs={24} className="select-machine__right">
          {renderRightBlock()}
        </Col>
      </Row>
    </div>
  );
};

SelectNewMachineBlock.propTypes = {
  selectedMachineData: PropTypes.shape({
    isExcludedMachine: PropTypes.bool,
  }).isRequired,
};

export default SelectNewMachineBlock;
