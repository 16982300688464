import './styles.scss';

import { Menu } from 'antd';
import B2BecLink from 'components/B2BLink';
import {
  ClientViewFilledIcon,
  ClientViewIcon,
  DebugRequestFilledIcon,
  DebugRequestIcon,
} from 'components/Icons';
import { analyzeLinkName } from 'libs/utils/adobeAnalysis';
import { linkGenerator } from 'libs/utils/language';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { selectIsRentalAvailable } from 'store/selectors/configSelector';
import { selectIsAdminView } from 'store/selectors/menuSelector';
import { selectIsKaercherAdmin } from 'store/selectors/userSelector';
import { trackLinkName } from 'store/slices/adobeAnalyticSlice';
import { setIsAdminView } from 'store/slices/menuSlice';
import { getSAPLog } from 'store/slices/SAPLogSlice';

import usePermission from '../../hooks/usePermission';
import {
  MODULE_ADMINISTRATION_RIGHTS,
  MODULE_DEBUGGING_RIGHTS,
} from '../../libs/constants/modulerights';
import { adminMenu, clientMenu } from './Content';

const { Item, Divider } = Menu;

const RenderMenu = (menu = clientMenu) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isRentalAvailable = useSelector(selectIsRentalAvailable);
  const { checkPermission } = usePermission();

  // To clone an object, Object can include number, string, symbol properties
  const clone = useCallback(
    (items) => items.map((item) => (Array.isArray(item) ? clone(item) : item)),
    []
  );

  const clonedMenu = clone(menu);

  const filteredMenu = useMemo(() => {
    return clonedMenu.filter((item) => {
      if (!isRentalAvailable && item.key === 'rental') return false;
      if (item?.requirePermission) {
        return checkPermission(item?.requirePermission);
      }
      return true;
    });
  }, [checkPermission, clonedMenu, isRentalAvailable]);

  const clickHandler = (linkName) => {
    if (linkName) {
      dispatch(trackLinkName(analyzeLinkName(linkName)));
    }
  };

  return filteredMenu.map(
    ({ key, isExternal, NormalIcon, SelectedIcon, title, path, linkName }) => {
      const menuItemContent = (
        <div>
          <div className="icons-wrapper">
            {NormalIcon}
            {SelectedIcon}
          </div>
          <span>{t(title)}</span>
        </div>
      );

      // Render the normal Link components
      return (
        <Item key={key} onClick={() => clickHandler(linkName)}>
          {isExternal ? (
            <a href={path} target="_blank" rel="noopener noreferrer">
              {menuItemContent}
            </a>
          ) : (
            <B2BecLink state={{ linkName }} to={path}>
              {menuItemContent}
            </B2BecLink>
          )}
        </Item>
      );
    }
  );
};

function SidebarMenu() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const isKaercherAdmin = useSelector(selectIsKaercherAdmin);
  const { hasPermission: canViewAdminArea } = usePermission(
    MODULE_ADMINISTRATION_RIGHTS.VIEW_ADMIN_AREA
  );
  const { hasPermission: canReadDebug } = usePermission(
    MODULE_DEBUGGING_RIGHTS.READ_DEBUG
  );

  const isAdminView = useSelector(selectIsAdminView);
  const isShowAdminDivider = canViewAdminArea || canReadDebug;

  const sidebarKeys = useMemo(() => {
    if (isAdminView) {
      return [...adminMenu].map(({ key }) => key);
    }
    return [...clientMenu].map(({ key }) => key);
  }, [isAdminView]);

  // To get the selected item on the sidebar to highlight it
  const currentKey = useMemo(
    () => sidebarKeys.find((key) => pathname.includes(key)) || sidebarKeys[0],
    [pathname, sidebarKeys]
  );

  const handleMenuItemClick = useCallback(
    (e) => {
      if (e.key === 'change-view') {
        dispatch(setIsAdminView());

        if (!isAdminView) {
          history.replace(linkGenerator('/admin-workspace'));
        }
      } else if (e.key === 'debug-request') {
        dispatch(getSAPLog());
      }
    },
    [dispatch, history, isAdminView]
  );

  return (
    <Menu
      className="sidebar__menu"
      mode="inline"
      onClick={handleMenuItemClick}
      selectedKeys={[currentKey]}
    >
      {isKaercherAdmin ? (
        <>
          {isAdminView ? RenderMenu(adminMenu) : RenderMenu()}

          {isShowAdminDivider && <Divider />}

          {canViewAdminArea && (
            <Item key="change-view">
              <div>
                <div className="icons-wrapper">
                  <ClientViewIcon className="my-workspace__icons--normal" />
                  <ClientViewFilledIcon className="my-workspace__icons--selected" />
                </div>
                <span>
                  {t(isAdminView ? 'sidebar.clientView' : 'sidebar.adminView')}
                </span>
              </div>
            </Item>
          )}
          {canReadDebug && (
            <Item key="debug-request">
              <div>
                <div className="icons-wrapper">
                  <DebugRequestIcon className="my-workspace__icons--normal" />
                  <DebugRequestFilledIcon className="my-workspace__icons--selected" />
                </div>
                <span>{t('sidebar.admin.debuggingRequests')}</span>
              </div>
            </Item>
          )}
        </>
      ) : (
        RenderMenu()
      )}
    </Menu>
  );
}

export default memo(SidebarMenu);
