import '../../components/mobileStyles.scss';

import { Drawer, Empty } from 'antd';
import { ChevronRightIcon, CloseIcon } from 'components/Icons';
import { isValidArray } from 'libs/utils/array';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import HeaderLogoSection from '../../components';
import BackButton from './components/BackButton';
import CategoryItem from './components/CategoryItem';
import CategoryTitle from './components/CategoryTitle';

function HeaderSubmenuMobile(props) {
  const {
    isLoading,
    isShow,
    onClose,
    onFocusSearch,
    btnName,
    productCategories,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();

  // Cat = Categories, Accs = Accessories, Det = Detergents
  const [isShowCatDrawer, setShowCatDrawer] = useState(false);
  const [isShowSubCatDrawer, setShowSubCatDrawer] = useState(false);
  const [isShowAccsDetSubCatDrawer, setShowAccsDetSubCatDrawer] =
    useState(false);

  const [subCatsToShow, setSubCatsToShow] = useState([]);
  const [accsDetSubCatsToShow, setAccsDetSubCatsToShow] = useState([]);

  const [parentCat, setParentCat] = useState({});
  const [parentSubCat, setParentSubCat] = useState({});

  const [machines, accessories, detergents] = productCategories?.map(
    ({ categoryId }) => categoryId
  );

  const CATEGORY_ID = useMemo(
    () => ({
      machines,
      accessories,
      detergents,
    }),
    [accessories, detergents, machines]
  );

  const onCloseAllDrawers = () => {
    setShowCatDrawer(false);
    setShowSubCatDrawer(false);
    setShowAccsDetSubCatDrawer(false);
  };

  useEffect(() => {
    if (!isShow) {
      onCloseAllDrawers();
    }
  }, [isShow]);

  const onClickSeeCatsOverview = () => {
    history.push(linkGenerator('/categories'));
    onCloseAllDrawers();
    onClose();
  };

  const onCatClick = (id, name, subCats, shouldShowSubCatsDrawer) => {
    if (shouldShowSubCatsDrawer) {
      setSubCatsToShow(subCats);
      setShowSubCatDrawer(true);
      setParentCat({ id, name });
    } else {
      history.push(linkGenerator(`/category/${id}`));
      onCloseAllDrawers();
      onClose();
    }
  };

  const onSubCatClick = (
    id,
    name,
    accsDetSubCats,
    _,
    shouldShowAccsDetSubCatsDrawer
  ) => {
    if (shouldShowAccsDetSubCatsDrawer) {
      setAccsDetSubCatsToShow(accsDetSubCats);
      setShowAccsDetSubCatDrawer(true);
      setParentSubCat({ id, name });
    } else {
      switch (parentCat?.id) {
        case CATEGORY_ID?.accessories:
          history.push(
            linkGenerator(
              `/accessories/category/${parentCat?.id}/subcategory/${id}`
            )
          );
          break;
        case CATEGORY_ID?.detergents:
          history.push(
            linkGenerator(
              `/detergents/category/${parentCat?.id}/subcategory/${id}`
            )
          );
          break;
        default:
          history.push(
            linkGenerator(`/category/${parentCat?.id}/subcategory/${id}`)
          );
      }
      onCloseAllDrawers();
      onClose();
    }
  };

  const onAccsDetSubCatClick = (
    id,
    name,
    _,
    __,
    ___,
    shouldShowAccsDetSubSubCatsDrawer
  ) => {
    if (shouldShowAccsDetSubSubCatsDrawer) {
      // TODO: handle showing subCats Drawer of Accs and Det subCats
    } else {
      switch (parentCat?.id) {
        case CATEGORY_ID?.accessories:
          history.push(
            linkGenerator(
              `/accessories/category/${parentSubCat?.id}/subcategory/${id}`
            )
          );
          break;
        case CATEGORY_ID?.detergents:
          history.push(
            linkGenerator(
              `/detergents/category/${parentSubCat?.id}/subcategory/${id}`
            )
          );
          break;
        default:
          history.push(linkGenerator(''));
      }
      onCloseAllDrawers();
      onClose();
    }
  };

  const buildDataForAccsAndDetType = (name, category) => {
    return {
      id: category?.categoryId,
      name,
      subCats: category?.subCategories?.map((item) => ({
        id: item?.categoryId,
        name: item?.categoryName,
        subCats: item?.subCategories?.map((subItem) => ({
          id: subItem?.categoryId,
          name: subItem?.categoryName,
          subCats: subItem?.subCategories,
          shouldShowAccsDetSubSubCatsDrawer: false,
        })),
        shouldShowAccsDetSubCatsDrawer:
          item?.subCategories && item?.subCategories?.length > 0,
      })),
      shouldShowSubCatsDrawer:
        category?.subCategories && category?.subCategories?.length > 0,
    };
  };

  const dataToShow = useMemo(() => {
    if (productCategories) {
      let result = [];
      if (productCategories[0]) {
        const productCategory = productCategories[0];
        result = productCategory?.subCategories?.map((item) => ({
          id: item?.categoryId,
          name: item?.categoryName,
          subCats: item?.subCategories.map((subItem) => ({
            id: subItem?.categoryId,
            name: subItem?.categoryName,
            subCats: subItem?.subCategories,
            shouldShowAccsDetSubCatsDrawer: false,
          })),
          shouldShowSubCatsDrawer:
            item?.subCategories && item?.subCategories.length > 0,
        }));
      }
      if (productCategories[1]) {
        const accessoryCategory = productCategories[1];
        result.push(
          buildDataForAccsAndDetType(
            t('searchResultsPage.header.accessories'),
            accessoryCategory
          )
        );
      }
      if (productCategories[2]) {
        const detergentsCategory = productCategories[2];
        result.push(
          buildDataForAccsAndDetType(
            t('searchResultsPage.header.detergents'),
            detergentsCategory
          )
        );
      }
      return result;
    }
    return [];
  }, [t, productCategories]);

  const renderCats = () => {
    if (isLoading) {
      return Array.from(Array(10).keys()).map((i) => (
        <CategoryItem loading key={i} id={0} name="" />
      ));
    }
    return dataToShow && dataToShow.length > 0 ? (
      dataToShow.map((item) => (
        <CategoryItem
          key={item?.id}
          id={item?.id}
          name={item?.name}
          subCats={item?.subCats}
          shouldShowSubCatsDrawer={item?.shouldShowSubCatsDrawer}
          onClick={onCatClick}
        />
      ))
    ) : (
      <Empty description={t('noData')} />
    );
  };

  const renderSubCats = (subCats) => {
    return isValidArray(subCats)
      ? subCats.map((item) => (
          <CategoryItem
            key={item.id}
            id={item.id}
            name={item.name}
            subCats={item.subCats}
            shouldShowAccsDetSubCatsDrawer={item.shouldShowAccsDetSubCatsDrawer}
            onClick={onSubCatClick}
          />
        ))
      : null;
  };

  const renderAccsDetSubCats = (accsDetSubCats) => {
    return isValidArray(accsDetSubCats)
      ? accsDetSubCats.map((item) => (
          <CategoryItem
            key={item.id}
            id={item.id}
            name={item.name}
            subCats={item.subCats}
            shouldShowAccsDetSubSubCatsDrawer={
              item.shouldShowAccsDetSubSubCatsDrawer
            }
            onClick={onAccsDetSubCatClick}
          />
        ))
      : null;
  };

  const renderAdditionalItem = (name) => {
    return <CategoryItem id={0} name={name} />;
  };

  const renderCatsDrawer = () => {
    return (
      <Drawer // Product Categories Drawer (with 2 items Accessories & Detergents at the end)
        className="header-menu-drawer header-menu-drawer__sub-drawer"
        title={
          <HeaderLogoSection
            inBurgerMenu
            onHideDrawerMobile={onClose}
            onFocusSearch={onFocusSearch}
          />
        }
        placement="right"
        visible={isShowCatDrawer}
        onClose={onClose}
        closable
        closeIcon={<CloseIcon />}
        width={window.innerWidth}
      >
        <BackButton
          name={t('header.drawer.button.back')}
          onClick={() => setShowCatDrawer(false)}
        />
        <CategoryTitle
          name={t('header.drawer.title')}
          onClick={onClickSeeCatsOverview}
        />
        {renderCats()}
      </Drawer>
    );
  };

  const renderSubCatsDrawer = () => {
    return (
      <Drawer // Product Sub Categories Drawer (or show Categories of Accessories/Detergents)
        className="header-menu-drawer header-menu-drawer__sub-drawer"
        title={
          <HeaderLogoSection
            inBurgerMenu
            onHideDrawerMobile={onClose}
            onFocusSearch={onFocusSearch}
          />
        }
        placement="right"
        visible={isShowSubCatDrawer}
        onClose={onClose}
        closable
        closeIcon={<CloseIcon />}
        width={window.innerWidth}
      >
        <BackButton
          name={t('header.drawer.button.back')}
          onClick={() => setShowSubCatDrawer(false)}
        />
        <CategoryTitle name={parentCat.name || ''} />
        {renderSubCats(subCatsToShow)}
        {renderAdditionalItem(t('searchResultsPage.header.accessories'))}
        {renderAdditionalItem(t('searchResultsPage.header.detergents'))}
      </Drawer>
    );
  };

  const renderAccsDetSubCatsDrawer = () => {
    return (
      <Drawer // Accessories/Detergents Sub Categories Drawer
        className="header-menu-drawer header-menu-drawer__sub-drawer"
        title={
          <HeaderLogoSection
            inBurgerMenu
            onHideDrawerMobile={onClose}
            onFocusSearch={onFocusSearch}
          />
        }
        placement="right"
        visible={isShowAccsDetSubCatDrawer}
        onClose={onClose}
        closable
        closeIcon={<CloseIcon />}
        width={window.innerWidth}
      >
        <BackButton
          name={t('header.drawer.button.back')}
          onClick={() => setShowAccsDetSubCatDrawer(false)}
        />
        <CategoryTitle name={parentSubCat.name || ''} />
        {renderAccsDetSubCats(accsDetSubCatsToShow)}
        {renderAdditionalItem(t('searchResultsPage.header.accessories'))}
        {renderAdditionalItem(t('searchResultsPage.header.detergents'))}
      </Drawer>
    );
  };

  return (
    <div className="submenu-mobile">
      <button
        className="submenu-mobile__btn"
        type="button"
        onClick={() => setShowCatDrawer(true)}
      >
        {btnName}
        <ChevronRightIcon />
      </button>
      {renderCatsDrawer()}
      {renderSubCatsDrawer()}
      {renderAccsDetSubCatsDrawer()}
    </div>
  );
}

HeaderSubmenuMobile.propTypes = {
  isLoading: PropTypes.bool,
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  onFocusSearch: PropTypes.func,
  btnName: PropTypes.string,
  productCategories: PropTypes.arrayOf(
    PropTypes.shape({
      subCategories: PropTypes.arrayOf(PropTypes.shape({})),
      categoryId: PropTypes.number,
    })
  ).isRequired,
};

HeaderSubmenuMobile.defaultProps = {
  isLoading: false,
  isShow: false,
  onClose: () => {},
  onFocusSearch: () => {},
  btnName: '',
};

export default HeaderSubmenuMobile;
