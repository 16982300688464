import { Card, Checkbox, Col, Row, Tooltip } from 'antd';
import clsx from 'clsx';
import B2becQuantity from 'components/B2becQuantity';
import QUANTITY_TYPE from 'components/B2becQuantity/constants';
import B2BecLink from 'components/B2BLink';
import ConfirmModal from 'components/ConfirmModal';
import FormatPrice from 'components/FormatPrice';
import { HeartFilledIcon } from 'components/Icons';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useDeviceDetect from '../../../hooks/useDeviceDetect';
import usePermission from '../../../hooks/usePermission';
import {
  MODULE_SHOP_NAVIGATION_RIGHTS,
  MODULE_WISHLIST_RIGHTS,
} from '../../../libs/constants/modulerights';
import ImageWithFallBack from '../../ImageWithFallback';
import styles from './WishlistProductCard.module.scss';

const WishlistProductCard = (props) => {
  const {
    name,
    category,
    img,
    imageUrl,
    materialNumber,
    materialId,
    listPrice,
    addToCartBtn,
    comparingProductIds,
    onCheckHandler,
    isCompareCheckboxDisplayed,
    requiresInstruction: isBProgramProduct,
    currencyUnit,
    displayAddToCart: shouldDisplayAddToCart,
    shouldDisplayListPrice,
    quantity,
    isRemovingProduct,
    onRemoveWishlistProductHandler,
    onChangeProductQuantityHandler,
    isSharedWishlist,
  } = props;

  const { t } = useTranslation();
  const { isLargeScreen } = useDeviceDetect();
  const [isShowRemoveModal, setShowRemoveModal] = useState(false);

  const isPriceEqualZero = +listPrice === 0 || listPrice === '';
  const [
    hasAddToCartPermission,
    hasUpdateWishlistQuantityPermission,
    hasCompareProductPermission,
  ] = usePermission(
    MODULE_WISHLIST_RIGHTS.ADD_WISHLIST_TO_CART,
    MODULE_WISHLIST_RIGHTS.ADD_PRODUCT_CHANGE_WISHLIST,
    MODULE_SHOP_NAVIGATION_RIGHTS.PRODUCT_COMPARISION
  );
  const isDisableQuantity =
    isSharedWishlist || !hasUpdateWishlistQuantityPermission;
  const shouldHideAddToCartButton =
    !shouldDisplayAddToCart ||
    isBProgramProduct ||
    isPriceEqualZero ||
    !hasAddToCartPermission;

  const isComparingProduct = (comparingProductIds || []).includes(
    materialNumber
  );

  const onDisplayRemoveModal = useCallback(() => {
    if (!isSharedWishlist) {
      setShowRemoveModal(true);
    }
  }, [isSharedWishlist]);

  const renderHoverLayer = () => (
    <div className={styles['hover-layer']}>
      <Row className={clsx(styles['functions-wrapper'], 'fadeIn-1')}>
        <Col>
          {hasCompareProductPermission && (
            <Checkbox
              className="kaercher-checkbox"
              checked={isComparingProduct}
              onChange={(e) =>
                onCheckHandler(
                  {
                    materialNumber,
                    materialName: name,
                    categoryName: category,
                    listPrice,
                    currencyUnit,
                    pictureUrl: img || imageUrl,
                    displayListPrice: shouldDisplayListPrice,
                  },
                  e
                )
              }
            >
              {t('productComparison.button')}
            </Checkbox>
          )}
        </Col>
        <Col style={{ textAlign: 'right' }}>
          {shouldHideAddToCartButton ? null : addToCartBtn}
        </Col>
      </Row>
    </div>
  );

  const mobileProductCard = () => {
    return (
      <Card className="wishlist-card-mobile">
        <div className="add-to-wishlist">
          <button
            type="button"
            className="button-as-link"
            onClick={onDisplayRemoveModal}
            disabled={isRemovingProduct}
          >
            <HeartFilledIcon />
          </button>
        </div>
        <div style={{ display: 'flex', height: 'inherit' }}>
          <div className={styles['left-wrapper']}>
            <B2BecLink
              className="fadeIn-2"
              to={`/product/${materialNumber || materialId}`}
            >
              <ImageWithFallBack
                alt={name}
                src={img}
                className="product-card__content-image"
              />
            </B2BecLink>

            <B2becQuantity
              wrapperClassName={styles.quantity}
              inputClassName={styles['quantity-input']}
              materialNumber={materialNumber || materialId}
              initialQuantity={quantity}
              onChange={onChangeProductQuantityHandler}
              type={QUANTITY_TYPE.WISHLIST_DETAIL}
              isParentDisabled={isDisableQuantity}
            />
          </div>

          <div className="product-card__content--right-wrapper">
            <B2BecLink
              style={{ marginBottom: 13.3 }}
              className="link-normalize fadeIn-08"
              to={`/product/${materialNumber || materialId}`}
            >
              <p className="fadeIn-05 fontBold product-card__content-name">
                {name}
              </p>

              <p className="fadeIn-05 product-card__content-material-number">
                {`${t('productCard.itemNumber')} `}
                {materialNumber || materialId}
              </p>
            </B2BecLink>

            <div className="product-card__content--lower-wrapper">
              <div className="fadeIn-1 product-card__list-price-wrapper">
                <span className="product-card__list-price-text">
                  {t('productCard.listPrice')}
                </span>
                <span className="fontBold product-card__list-price-number">
                  {isPriceEqualZero ? (
                    t('productCard.onRequest')
                  ) : (
                    <FormatPrice
                      price={listPrice}
                      currencyUnit={currencyUnit}
                    />
                  )}
                </span>
              </div>

              {shouldHideAddToCartButton ? null : addToCartBtn}
            </div>
          </div>
        </div>
      </Card>
    );
  };

  const desktopProductCard = () => {
    return (
      <div className={styles.container}>
        <div>
          <div className={styles['heart-container']}>
            <button
              type="button"
              className={styles.heart}
              onClick={onDisplayRemoveModal}
              disabled={isRemovingProduct}
            >
              <HeartFilledIcon />
            </button>
          </div>
          <div className={styles.imageWrapper}>
            <B2becQuantity
              wrapperClassName={styles.quantity}
              inputClassName={styles['quantity-input']}
              materialNumber={materialNumber || materialId}
              initialQuantity={quantity}
              onChange={onChangeProductQuantityHandler}
              isParentDisabled={isDisableQuantity}
              type={QUANTITY_TYPE.WISHLIST_DETAIL}
            />
            <B2BecLink
              className="ant-card-cover fadeIn-2"
              to={`/product/${materialNumber || materialId}`}
            >
              <ImageWithFallBack alt={name} src={img || imageUrl} />
            </B2BecLink>
          </div>
          <B2BecLink
            className={styles['link-normalize']}
            to={`/product/${materialNumber || materialId}`}
          >
            <Tooltip title={name}>
              <p className={styles['product-card-title']}>{name}</p>
            </Tooltip>
          </B2BecLink>
          <B2BecLink
            className={styles['link-normalize']}
            to={`/product/${materialNumber || materialId}`}
          >
            <p className={styles['product-card-item-number']}>
              {`${t('productCard.itemNumber')} `}
              {materialNumber || materialId}
            </p>
          </B2BecLink>
          <Row
            justify="space-between"
            className={clsx(styles['list-price-wrapper'], 'fadeIn-1')}
          >
            <Col className={styles['list-price-title']}>
              {t('productCard.listPrice')}
            </Col>
            <Col className={styles['list-price-price']}>
              {isPriceEqualZero || !shouldDisplayListPrice ? (
                t('productCard.onRequest')
              ) : (
                <FormatPrice price={listPrice} currencyUnit={currencyUnit} />
              )}
            </Col>
          </Row>
        </div>
        {isLargeScreen && isCompareCheckboxDisplayed && renderHoverLayer()}
      </div>
    );
  };

  return (
    <>
      {isLargeScreen ? desktopProductCard() : mobileProductCard()}

      <ConfirmModal
        isShowModal={isShowRemoveModal && !isSharedWishlist}
        setIsShowModal={setShowRemoveModal}
        modalTitle={t('wishlist.modal.removeProduct.title')}
        modalContent={t('wishlist.modal.removeProduct.modalContent')}
        confirmActionHandler={() =>
          onRemoveWishlistProductHandler(materialNumber)
        }
      />
    </>
  );
};

WishlistProductCard.propTypes = {
  name: PropTypes.string,
  img: PropTypes.string,
  imageUrl: PropTypes.string,
  category: PropTypes.string,
  materialNumber: PropTypes.string,
  materialId: PropTypes.string,
  listPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  addToCartBtn: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onCheckHandler: PropTypes.func,
  isCompareCheckboxDisplayed: PropTypes.bool,
  requiresInstruction: PropTypes.bool,
  comparingProductIds: PropTypes.arrayOf(PropTypes.string),
  currencyUnit: PropTypes.string,
  displayAddToCart: PropTypes.bool,
  shouldDisplayListPrice: PropTypes.bool,
  isRemovingProduct: PropTypes.bool,
  quantity: PropTypes.number,
  onRemoveWishlistProductHandler: PropTypes.func,
  onChangeProductQuantityHandler: PropTypes.func,
  isSharedWishlist: PropTypes.bool,
};

WishlistProductCard.defaultProps = {
  name: '',
  img: '',
  imageUrl: '',
  category: '',
  materialNumber: '',
  materialId: '',
  listPrice: 0 || '',
  addToCartBtn: '',
  onCheckHandler: () => {},
  isCompareCheckboxDisplayed: false,
  requiresInstruction: false,
  comparingProductIds: [],
  currencyUnit: '',
  displayAddToCart: true,
  shouldDisplayListPrice: true,
  isRemovingProduct: false,
  quantity: 1,
  onRemoveWishlistProductHandler: () => {},
  onChangeProductQuantityHandler: () => {},
  isSharedWishlist: false,
};

export default WishlistProductCard;
