// import './styles.scss';
import styles from './RoleDetails.module.scss';

import B2becInfoLayout from 'components/B2becInfoLayout';
import B2becTable from 'components/B2becTable';
import CustomButton from 'components/CustomButton';
import { ChevronUpIcon } from 'components/Icons';
import { useBreadcrumbs, useDocumentTitle } from 'hooks';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  getRoleHierarchy,
  selectRoleDetails,
} from 'store/selectors/roleSelector';
import { getHierarchy, getRoleDetails } from 'store/slices/roleSlice';

import RoleInformation from '../components/RoleInformation';
import renderColumns from './renderColumns';
import B2becTableExpandIcon from 'components/B2becTableExpandIcon';
import { checkHasPropertiesInObject } from 'libs/utils/object';
import { isValidArray } from 'libs/utils/array';

const RoleDetailPage = (props) => {
  const { title, breadcrumbs, pageId } = props;
  const { roleId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs, [{ titleData: [roleId], pathData: [roleId] }]);
  useAdobeAnalysis(null, {
    pageName: 'roleDetails management detail view',
    pageId,
    pageType: PAGE_TYPES.ROLE_MANAGEMENT,
  });

  const roleDetails = useSelector(selectRoleDetails);
  const parentHierarchy = useSelector(getRoleHierarchy);

  const [expandingClusters, setExpandingClusters] = useState([]);

  useEffect(() => {
    if (roleId) {
      dispatch(getRoleDetails(roleId));
    }
  }, [dispatch, roleId]);
  // get Hierarchy List
  useEffect(() => {
    dispatch(getHierarchy());
  }, [dispatch]);

  const renderExpandedRowClasses = useCallback(
    (record) => {
      if (expandingClusters.includes(record?.clusterId)) {
        return styles.expandedRow;
      }
      return '';
    },
    [expandingClusters]
  );

  const onEditRole = useCallback(() => {
    history.push(linkGenerator(`/roles-management/edit/${roleId}`));
  }, [history, roleId]);

  /*
  * Input: [
            { clusterName: 'A', rights: [{ rightId: '123' }] },
            { clusterName: 'Not grouped', rights: [{ rightId: '456' }, { rightId: '789' }] }
          ]
  * Output: [
            { clusterName: 'A', rights: [{ rightId: '123' }] },
            { rightId: '456' },
            { rightId: '789' }
          ]
  */
  const mappedRoleDetails = useMemo(() => {
    if (checkHasPropertiesInObject(roleDetails, ['rightClusters'])) {
      const { rightClusters } = roleDetails;

      const groupedRights = isValidArray(rightClusters)
        ? rightClusters.map((cluster) => ({
            ...cluster,
            rights: cluster?.rights.map((right) => ({
              ...right,
              clusterName: cluster?.clusterName,
            })),
            isSelected: cluster?.rights.some((right) => right?.isSelected),
          }))
        : [];

      return {
        ...roleDetails,
        rightClusters: groupedRights,
      };
    }
    return roleDetails;
  }, [roleDetails]);

  return (
    <div className={styles.container}>
      <B2becInfoLayout>
        <B2becInfoLayout.Title
          title={roleDetails?.roleName}
          titleExtra={
            <div>
              <CustomButton onClick={onEditRole}>
                {t('roleManagement.editRole')}
              </CustomButton>
            </div>
          }
        >
          <RoleInformation
            hierarchy={parentHierarchy}
            description={roleDetails?.description}
          />
        </B2becInfoLayout.Title>
        <B2becInfoLayout.Content>
          <B2becTable
            rowKey={(record) => `${record?.clusterId}-${record?.rightId}`}
            striped={false}
            dataSource={mappedRoleDetails?.rightClusters}
            columns={renderColumns()}
            showSorterTooltip={false}
            className={styles.table}
            rowClassName={renderExpandedRowClasses}
            expandIcon={B2becTableExpandIcon}
            expandable={{
              expandedRowRender: (record) => (
                <B2becTable
                  rowKey="rightId"
                  striped={false}
                  dataSource={record?.rights}
                  columns={renderColumns()}
                  showHeader={false}
                />
              ),
              onExpand: (expanded, record) => {
                // for adding classes name purpose.
                if (expanded) {
                  setExpandingClusters((expandingList) => [
                    ...expandingList,
                    record?.clusterId,
                  ]);
                } else {
                  setExpandingClusters(
                    expandingClusters.filter(
                      (clusterId) => clusterId !== record?.clusterId
                    )
                  );
                }
              },
              expandIcon: ({ expanded, onExpand, record }) => (
                <ChevronUpIcon
                  onClick={(e) => onExpand(record, e)}
                  style={{
                    transform: expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                  }}
                />
              ),
            }}
          />
        </B2becInfoLayout.Content>
      </B2becInfoLayout>
    </div>
  );
};

RoleDetailPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default RoleDetailPage;
