import './styles.scss';

import { LoadingOutlined } from '@ant-design/icons';
import CustomButton from 'components/CustomButton';
import GroupFilter from 'components/GroupFilter';
import { UploadIcon } from 'components/Icons';
import ImportConfirmModal from 'components/ImportConfirmModal';
import { NotificationManager } from 'components/Notification';
import { ACCEPTED_UPLOAD_FILE_EXTENSIONS } from 'libs/constants';
import { USER_TYPES } from 'libs/constants/user';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserService } from 'services';
import { adminFapiUserManagement } from 'services/adminFapi';
import { selectUserCountryId } from 'store/selectors/userSelector';

import PermissionWrapper from '../../../../../HOCs/permissionWrapper';
import { MODULE_USER_MANAGEMENT_RIGHTS } from '../../../../../libs/constants/modulerights';

const Title = ({ userType, setCurrentPage, resetToInitialState }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isUploading, setIsUploading] = useState(false);
  const [isShowUploading, setIsShowUploading] = useState(false);
  const countryId = useSelector(selectUserCountryId);

  const filterGroupes = useMemo(() => {
    return Object.entries(USER_TYPES).map(([_, value]) => ({
      key: value,
      name: t(`userManagement.filterGroups.${value}`),
    }));
  }, [t]);

  const handleChangeUserType = (selectedUserType) => {
    let dest = linkGenerator('/user-management');

    if (selectedUserType !== USER_TYPES.CUSTOMER_USER) {
      dest += `/${selectedUserType}`;
    }

    resetToInitialState();
    history.push(dest);
  };

  const handleUploadSuccess = useCallback((message) => {
    setIsUploading(false);
    NotificationManager.success({
      message,
    });
  }, []);

  const handleUploadError = useCallback((message) => {
    setIsUploading(false);
    NotificationManager.error({
      message,
    });
  }, []);

  const customRequest = useCallback(
    async ({ file }) => {
      setIsUploading(true);

      const isCSV = ACCEPTED_UPLOAD_FILE_EXTENSIONS?.includes(file.type);

      if (!isCSV) {
        handleUploadError('notification.upload.csvOnly');
        return;
      }

      try {
        const { data: presignedUrl } =
          await adminFapiUserManagement.getPresignedUrl(file?.name);
        const uploadInvitationsToS3 = await UserService.uploadInvitationsToS3(
          presignedUrl,
          file
        );

        if (uploadInvitationsToS3?.status === 200) {
          handleUploadSuccess('notification.success.upload');
        }
      } catch (e) {
        handleUploadError('notification.error.submit');
      } finally {
        setCurrentPage(1);
        setIsShowUploading(false);
      }
    },
    [handleUploadSuccess, handleUploadError, setIsShowUploading, setCurrentPage]
  );

  return (
    <div className="full-width-page-component__wrapper">
      <div className="user-management__title-wrapper">
        <div className="user-management__title-content">
          <h1>{t('pageTitle.userManagement')}</h1>
          <div className="flex">
            <GroupFilter
              value={userType}
              groups={filterGroupes}
              onChange={handleChangeUserType}
            />
            <div className="user-management__title__buttons">
              {USER_TYPES.CUSTOMER_USER !== userType ? (
                <PermissionWrapper
                  permission={MODULE_USER_MANAGEMENT_RIGHTS.CREATE_INVITATION}
                >
                  <CustomButton
                    className="user-management__title__buttons__add-new"
                    onClick={() => {
                      history.push(linkGenerator(`/add-${userType}-user`));
                    }}
                  >
                    {t('userManagement.addUser')}
                  </CustomButton>
                </PermissionWrapper>
              ) : (
                <>
                  <PermissionWrapper
                    permission={
                      MODULE_USER_MANAGEMENT_RIGHTS.MASS_UPLOAD_INVITATION
                    }
                  >
                    <CustomButton
                      disabled={isUploading}
                      onClick={() => setIsShowUploading(true)}
                    >
                      {isUploading ? <LoadingOutlined /> : <UploadIcon />}
                      {t('userManagement.uploadUsers')}
                    </CustomButton>
                  </PermissionWrapper>

                  <PermissionWrapper
                    permission={MODULE_USER_MANAGEMENT_RIGHTS.CREATE_INVITATION}
                  >
                    <CustomButton
                      className="user-management__title__buttons__add-new"
                      onClick={() => {
                        history.push(linkGenerator('/user-invitation'));
                      }}
                    >
                      {t('userManagement.userInvitation')}
                    </CustomButton>
                  </PermissionWrapper>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <ImportConfirmModal
        isShowModal={isShowUploading}
        modalTitle={t('userManagement.uploadUsers')}
        modalContent={t('userManagement.messages.uploadUserConfirmModal', {
          country: countryId,
        })}
        setIsShowModal={setIsShowUploading}
        onImportPortfolio={customRequest}
      />
    </div>
  );
};

Title.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
  resetToInitialState: PropTypes.func.isRequired,
  userType: PropTypes.string,
};

Title.defaultProps = {
  userType: USER_TYPES.CUSTOMER_USER,
};

export default Title;
