import './styles.scss';

import { Typography } from 'antd';
import { ReactComponent as LeftIconArrow } from 'assets/icons/chevron-left.svg';
import B2becTranslation from 'components/B2becTranslation';
import CustomButton from 'components/CustomButton';
import { notificationComponent } from 'components/Notification';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useAsync from 'hooks/useAsync';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { NOTIFICATION_TYPE } from 'libs/constants/notification';
import { linkGenerator } from 'libs/utils/language';
import { isFormDirty } from 'libs/utils/validatorUtils';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fapiCustomerService } from 'services/fapi';
import { selectProvinces } from 'store/selectors/configSelector';

import InvoiceChangeForm from './components/InvoiceChangeForm';

const ProfileChangeInvoicePage = (props) => {
  const { title, pageId } = props;
  useDocumentTitle(title);

  useAdobeAnalysis(null, {
    pageId,
    pageType: PAGE_TYPES.PROFILE,
    pageName: 'change invoice address',
  });

  const { t } = useTranslation();
  const history = useHistory();
  const [formValues, setFormValues] = useState({});
  const provinces = useSelector(selectProvinces);

  const IS_NOT_DIRTY_MESSAGE = 'IS_NOT_DIRTY_MESSAGE';

  const submitInvoiceChange = useCallback(
    async (params) => {
      const isDirty = isFormDirty(formValues, params);
      if (!isDirty) {
        throw new Error(IS_NOT_DIRTY_MESSAGE);
      }
      await fapiCustomerService.submitInvoiceChange(params);
      setFormValues(params);
    },
    [formValues]
  );

  const { execute, status, error } = useAsync(submitInvoiceChange, false);

  const handleRequestInvoiceChange = (values) => {
    const bodyValues = values;
    if (bodyValues.region && provinces[bodyValues.region]) {
      bodyValues.region = provinces[bodyValues.region].label;
    }

    execute(bodyValues).then(({ error: err }) => {
      window.scrollTo({ top: 0 });

      if (err && err.message !== IS_NOT_DIRTY_MESSAGE) {
        notificationComponent({
          type: NOTIFICATION_TYPE.ERROR,
          message: (
            <B2becTranslation value={`checkout.${err?.response?.data}`} />
          ),
        });
        return;
      }

      notificationComponent({
        type: NOTIFICATION_TYPE.SUCCESS,
        message: (
          <B2becTranslation value="changeInvoiceAddress.successfullMessage" />
        ),
      });
    });
  };

  const isLoading = status === 'pending';
  const isDirty = error?.message !== IS_NOT_DIRTY_MESSAGE;
  const isError = status === 'error' && isDirty;

  return (
    <div className="b2bec-change-invoice">
      <div className="b2bec-change-invoice__header">
        <h1>{t('changeInvoiceAddress.title')}</h1>

        <CustomButton
          className="back-btn"
          buttonSize="small"
          onClick={() => history.push(linkGenerator('/profile'))}
        >
          <LeftIconArrow />
          {t('searchResultsPage.button.backToOverview')}
        </CustomButton>
      </div>

      <div className="b2bec-change-invoice__description">
        {t('changeInvoiceAddress.description')}
      </div>

      {isError ? (
        <Typography.Text
          className="b2bec-change-invoice__message"
          type="danger"
        >
          {t('changeInvoiceAddress.errorMessage')}
        </Typography.Text>
      ) : null}

      <InvoiceChangeForm
        onFinish={handleRequestInvoiceChange}
        disabled={isLoading}
        isSubmiting={isLoading}
      />
    </div>
  );
};

ProfileChangeInvoicePage.propTypes = {
  title: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
};
export default ProfileChangeInvoicePage;
