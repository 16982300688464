import { PunchoutProvider } from 'contexts/punchout-context';
import withUserAndDateFormat from 'HOCs/withUserAndDateFormat';
import { USER_ROLES } from 'libs/constants';
import { isValidArray } from 'libs/utils/array';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import DashboardRouteComponent from 'routes/DashboardRouteComponent';
import withCheckingUserStatus from 'routes/withCheckingUserStatus';
import {
  selectIsLoggedIn,
  selectUserId,
} from 'store/selectors/authenticationSelectors';
import { selectUserRole } from 'store/selectors/userSelector';

import usePermission from '../hooks/usePermission';

const ProtectedRoute = ({
  component: Component,
  allowedRoles,
  title,
  breadcrumbs,
  pageID,
  path,
  exact,
  requirePermission,
}) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userId = useSelector(selectUserId);
  const userPolicy = useSelector(selectUserRole);
  const loginPath = linkGenerator('/');
  const { hasPermission } = usePermission(requirePermission);

  const renderCallback = (props) => {
    const currentPath = props.location.pathname;
    if (!isLoggedIn && !userId) {
      return redirectToLoginFrom(currentPath);
    }
    if (isAdminPanel() && isNoAdminUser()) {
      return redirectToWorkspace();
    }
    return component(props, currentPath);
  };

  const isNoAdminUser = () => {
    return userPolicy !== USER_ROLES.KaercherAdmin;
  };

  const isAdminPanel = () => {
    return (
      isValidArray(allowedRoles) &&
      allowedRoles.includes(USER_ROLES.KaercherAdmin)
    );
  };

  const redirectToWorkspace = () => {
    return (
      <Redirect
        to={{
          pathname: '/my-workspace',
        }}
      />
    );
  };

  const redirectToLoginFrom = (currentPath) => {
    return (
      <Redirect
        to={{
          pathname: loginPath,
          state: { from: currentPath },
        }}
      />
    );
  };

  if (!!requirePermission && !hasPermission) {
    return <Redirect to={{ pathname: '/403' }} />;
  }

  const component = (props) => {
    return (
      <PunchoutProvider>
        <DashboardRouteComponent>
          <Component
            title={title}
            breadcrumbs={breadcrumbs}
            pageId={pageID}
            {...props}
          />
        </DashboardRouteComponent>
      </PunchoutProvider>
    );
  };

  return <Route path={path} exact={exact} render={renderCallback} />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  checkStatus: PropTypes.bool,
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  pageID: PropTypes.string,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  exact: PropTypes.bool,
  requirePermission: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

ProtectedRoute.defaultProps = {
  checkStatus: false,
  title: '',
  pageID: '1',
  path: '',
  exact: false,
  allowedRoles: [],
  requirePermission: false,
};

export default withCheckingUserStatus(withUserAndDateFormat(ProtectedRoute));
