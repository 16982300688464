export const CONFIG_LEVELS = {
  GLOBAL: 'Host',
  COUNTRY: 'Country',
  CUSTOMER: 'Customer',
};

export const CONFIG_GROUP_TYPE_ALL = {
  key: 'all',
  name: 'all',
};

export const CONFIG_DATA_TYPES = {
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  DATE: 'date',
  OBJECT: 'object',
  ARRAY: 'array',
  PASSWORD: 'password',
};

export const DEFAULT_CONFIG_SCOPE = {
  level: CONFIG_LEVELS.GLOBAL,
  countryId: undefined,
  customerNumber: undefined,
};

export const CONFIG_NAMES = {
  SAP_Downtime_Start_Time: 'SAP_Downtime_Start_Time',
  SAP_Downtime_End_Time: 'SAP_Downtime_End_Time',
  Service_Advisor_Link: 'service_advisor_link',
  SERVICE_REPAIR_PAGE: 'service_repair_page',
  SHOW_SERVICE_CODE: 'Show_Service_Code',
  ORDER_REFERENCE_MANDATORY: 'Order_reference_mandatory',
  SHIPPING_NOTES_MANDATORY: 'Shipping_notes_mandatory',
  AVAILABLE_LANGUAGES: 'Available_Languages',
  NUMBER_SEPARATOR: 'Numbers_Separator',
  SERVICE_PACKAGE_PAGE: 'service_package_page',
  SHOW_SPARE_PART_LINK: 'show_spare_part_links',
  CURRENCY_POSITION_FRONT: 'currency_position_front',
  CURRENTCY_SYMBOL: 'currency_symbol',
  LOGO: 'Logo',
  DATE_FORMAT: 'date_format',
  EQUIPMENT_MANAGER_LINK: 'Equipment_Manager_link',
  HIDE_QUICK_ENTRY: 'Hide_quick_entry',
  SHOW_CONTACT_FORM: 'show_contact_form',
  SHOW_RENTAL: 'Rental_Available',
  ADDRESS_SCHEME: 'Address_scheme',
  JP_PROVINCES: 'JP_provinces',
  AVAILABLE_COUNTRIES: 'Available_Countries',
  AVAILABLE_SALESORG: 'Available_SalesOrg',
};

export const USER_CONFIG_KEYS = [
  {
    configurationKey: CONFIG_NAMES.AVAILABLE_LANGUAGES,
    dataTypeDto: CONFIG_DATA_TYPES.ARRAY,
  },
  {
    configurationKey: CONFIG_NAMES.NUMBER_SEPARATOR,
    dataTypeDto: CONFIG_DATA_TYPES.STRING,
  },
  {
    configurationKey: CONFIG_NAMES.SERVICE_PACKAGE_PAGE,
    dataTypeDto: CONFIG_DATA_TYPES.STRING,
  },
  {
    configurationKey: CONFIG_NAMES.SHOW_SPARE_PART_LINK,
    dataTypeDto: CONFIG_DATA_TYPES.BOOLEAN,
  },
];

export const CONFIG_NUMBERS_SEPARATOR = [
  {
    key: 'en-GB',
    value: 'en-GB',
    label: '1,000.00',
  },
  {
    key: 'de-DE',
    value: 'de-DE',
    label: '1.000,00',
  },
  {
    key: 'cs-CZ',
    value: 'cs-CZ',
    label: '1 000,00',
  },
];

export const CONFIG_DATE_FORMAT = [
  {
    key: 'DD.MM.YYYY',
    value: 'DD.MM.YYYY',
    label: 'DD.MM.YYYY',
  },
  {
    key: 'YYYY-MM-DD',
    value: 'YYYY-MM-DD',
    label: 'YYYY-MM-DD',
  },
];

export const DEFAULT_CONFIG_DATE_FORMAT = CONFIG_DATE_FORMAT[0].value;

export const CONFIG_LOGOS = [
  {
    key: 'futuretech',
    value: 'futuretech',
    label: 'Futuretech',
  },
  {
    key: 'karcher',
    value: 'karcher',
    label: 'Kärcher',
  },
];

export const CONFIG_ADDRESS_SCHEME = [
  {
    value: '',
    label: 'Default',
  },
  {
    value: 'japan',
    label: 'Japan',
  },
];

// This object is used temporarily to get countryName from available countries
// After available countries is on redux store, it have to be transfered to
// get from redux store.
export const AVAILABLE_COUNTRIES = [
  { countryId: 'Austria', countryName: 'AT' },
  { countryId: 'Switzerland', countryName: 'CH' },
  { countryId: 'Poland', countryName: 'PL' },
  { countryId: 'Netherlands', countryName: 'NL' },
  { countryId: 'Germany', countryName: 'DE' },
  { countryId: 'United Kingdom', countryName: 'GB' },
  { countryId: 'France', countryName: 'FR' },
  { countryId: 'Belgium', countryName: 'BE' },
  { countryId: 'Russia', countryName: 'RU' },
  { countryId: 'Czech', countryName: 'CZ' },
  { countryId: 'Finland', countryName: 'FI' },
  { countryId: 'Denmark', countryName: 'DK' },
  { countryId: 'Sweden', countryName: 'SE' },
  { countryId: 'Japan', countryName: 'JP' },
  { countryId: 'Hungary', countryName: 'HU' },
];
