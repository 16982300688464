import { NotificationManager } from 'components/Notification';
import { TRACK_DATA } from 'libs/constants/adobeAnalytics';
import { CONFIG_NAMES, USER_CONFIG_KEYS } from 'libs/constants/configs';
import { USER_TYPES } from 'libs/constants/user';
import { logErrors } from 'libs/utils/common';
import { handleChangeLanguage, linkGenerator } from 'libs/utils/language';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { fapiUserService } from 'services/fapi';
import { selectUserType } from 'store/selectors/authenticationSelectors';
import { updateTrackStatus } from 'store/slices/adobeAnalyticSlice';
import { getSubmenuProductCategoryList } from 'store/slices/categorySlice';
import { setupActions } from 'store/slices/setupSlice';
import { userActions } from 'store/slices/userSlice';

function* getUserConfigurationsSaga() {
  try {
    const { fapiConfigurationService } = yield getContext('dependencies');
    const data = yield fapiConfigurationService.getConfigsByNames(
      USER_CONFIG_KEYS
    );

    yield put(
      userActions.getUserConfigurationsSucceeded({
        availableLanguages: JSON.parse(data[CONFIG_NAMES.AVAILABLE_LANGUAGES]),
        showSparePartLink: data[CONFIG_NAMES.SHOW_SPARE_PART_LINK],
        servicePackagePage: data[CONFIG_NAMES.SERVICE_PACKAGE_PAGE],
        numberSeparator: data[CONFIG_NAMES.NUMBER_SEPARATOR],
      })
    );
  } catch (err) {
    yield put(updateTrackStatus({ trackSection: TRACK_DATA.APPLICATION }));
    yield put(userActions.getUserConfigurationsFailed());
  }
}

function* getUserInfoSaga({ payload }) {
  try {
    const { userId } = payload;
    const { data } = yield call(fapiUserService.getUserDetailsById, userId);
    const userInfo = data?.userInfo;
    yield put(userActions.getUserDetailsSucceeded(data));

    handleChangeLanguage(userInfo?.language, userInfo?.countryId);
  } catch (error) {
    yield put(userActions.getUserDetailsFailed());
    logErrors(error);
  }
}

function* requestChangeLanguageSaga({ payload }) {
  const { userId, newLanguage, countryId } = payload;

  try {
    yield fapiUserService.changeUserLanguage(userId, newLanguage);
    const userType = yield select(selectUserType);

    if (userType === USER_TYPES.CUSTOMER_USER) {
      yield put(setupActions.fetchSetupData());
    } else {
      yield put(getSubmenuProductCategoryList());
    }

    yield put(userActions.requestChangeLanguageSucceeded());
    yield put(
      userActions.changeUserSettingsLanguage({
        language: newLanguage,
        countryId,
      })
    );

    handleChangeLanguage(newLanguage, countryId);
  } catch (error) {
    // Revert i18next language if request change language failed
    const { initialLangCode } = payload;

    NotificationManager.error({
      message: 'notification.error.update',
    });

    handleChangeLanguage(initialLangCode, countryId);

    yield put(userActions.requestChangeLanguageFailed(error.message));
  }
}

function* getCurrentTechnicalUserInfoSaga() {
  try {
    const { history } = yield getContext('dependencies');
    const { data } = yield call(fapiUserService.getCurrentTechnicalUserInfo);

    yield put(userActions.getCurrentTechnicalUserInfoSucceeded(data));

    handleChangeLanguage(data?.language, data?.countryId);

    history.push(linkGenerator('/my-workspace'));
  } catch (error) {
    yield put(userActions.getCurrentTechnicalUserInfoFailed());
    console.error(
      'error when getting current technical user info',
      error.message
    );
  }
}

export default function* userSaga() {
  yield takeLatest(userActions.getUserDetails.toString(), getUserInfoSaga);
  yield takeLatest(
    userActions.getUserConfigurations.toString(),
    getUserConfigurationsSaga
  );
  yield takeLatest(
    userActions.requestChangeLanguage.toString(),
    requestChangeLanguageSaga
  );
  yield takeLatest(
    userActions.getCurrentTechnicalUserInfo.toString(),
    getCurrentTechnicalUserInfoSaga
  );
}
