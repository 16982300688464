import { Modal } from 'antd';
import clsx from 'clsx';
import B2becButton from 'components/B2becButton';
import { ChevronLeftIcon } from 'components/Icons';
import { NotificationManager } from 'components/Notification';
import { useMediaQuery } from 'hooks/useMediaQuery';
import BREAKPOINTS from 'libs/constants/breakpoints';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectUserId } from 'store/selectors/authenticationSelectors';
import {
  getWishlistOverview,
  useCreateWishlistMutation,
} from 'store/slices/wishlistSlice';

import { NewWishlistNameInput } from '../NewWishlistNameInput';
import styles from './CreateWishlistModal.module.scss';

function CreateWishlistModal({ visible, onClose }) {
  // Remove this ref when upgrading to React 18
  const isMountedRef = useRef(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ownerId = useSelector(selectUserId);
  const [createWishlist] = useCreateWishlistMutation();
  const [newWishlistName, setNewWishlistName] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const isLargerThan992 = useMediaQuery(`(min-width:${BREAKPOINTS.lg}px)`);
  const { pathname } = useLocation();

  const canSave = newWishlistName.trim().length > 0;

  const handleChangeNewWishlistName = (e) => {
    setNewWishlistName(e.target.value);
  };

  const handleSave = async () => {
    if (canSave) {
      try {
        setIsCreating(true);
        await createWishlist({
          wishlistName: newWishlistName,
        });

        if (pathname.includes('/wishlists')) {
          dispatch(
            getWishlistOverview({
              ownerId,
              searchQuery: '',
              pageNumber: 1,
            })
          );
        }

        NotificationManager.success({
          message: 'notification.success.createWishlist',
        });
        onClose();
      } catch (error) {
        NotificationManager.error({
          message: 'notification.error.createWishlist',
        });
        console.error('fail to create new wishlist ', error);
      } finally {
        if (isMountedRef.current) {
          setIsCreating(false);
        }
      }
    }
  };

  const headerSection = (
    <div className={styles['modal__title-wrapper']}>
      <div className={styles['modal__title-left']}>
        {!isLargerThan992 && (
          <button
            className={clsx('button-as-link', styles['modal__close-button'])}
            onClick={onClose}
            type="button"
          >
            <ChevronLeftIcon width={24} height={24} />
          </button>
        )}
        <h1 className={styles.modal__title}>
          {t('wishlist.modal.newWishlist.title')}
        </h1>
      </div>
      {!isLargerThan992 && (
        <div className="modal__title-right">
          <B2becButton
            className={clsx('button-as-link', styles['modal__save-button'])}
            loading={isCreating}
            onClick={handleSave}
          >
            {t('buttonTexts.save')}
          </B2becButton>
        </div>
      )}
    </div>
  );

  const newWishlistSection = (
    <>
      <span className={styles['new-wishlist__label']}>
        {t('wishlist.createNewWishlist')}
      </span>
      <NewWishlistNameInput
        className={styles['new-wishlist__input--no-margin']}
        placeholder={t('wishlist.placeholder.newWishlistName')}
        value={newWishlistName}
        onChange={handleChangeNewWishlistName}
      />
    </>
  );

  const CTASection = isLargerThan992 ? (
    <div className={styles['modal__action-buttons']}>
      <B2becButton
        className={styles['modal__action-button']}
        disabled={isCreating}
        ghost
        onClick={onClose}
      >
        {t('buttonTexts.cancel')}
      </B2becButton>
      <B2becButton
        className={styles['modal__action-button']}
        disabled={!canSave}
        loading={isCreating}
        onClick={handleSave}
      >
        {t('buttonTexts.save')}
      </B2becButton>
    </div>
  ) : null;

  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    []
  );

  return (
    <Modal
      centered
      className={styles.modal}
      closable={false}
      destroyOnClose
      footer={null}
      width={isLargerThan992 ? 540 : '100%'}
      visible={visible}
      onCancel={onClose}
    >
      {headerSection}
      {!isLargerThan992 && <div className={styles['break-line']} />}
      {isLargerThan992 && (
        <p className={styles.modal__description}>
          {t('wishlist.modal.newWishlist.description')}
        </p>
      )}
      {newWishlistSection}
      {isLargerThan992 && <div className={styles['break-line']} />}
      {CTASection}
    </Modal>
  );
}

CreateWishlistModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateWishlistModal;
