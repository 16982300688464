import './styles.scss';

import { Col, Modal, Row } from 'antd';
import CustomButton from 'components/CustomButton';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ConfirmModal = (props) => {
  const {
    modalTitle,
    modalContent,
    isShowModal,
    setIsShowModal,
    confirmActionHandler,
  } = props;
  const { t } = useTranslation();

  const onConfirmHandler = () => {
    setIsShowModal(false);

    confirmActionHandler();
  };

  const onCancelHandler = () => {
    setIsShowModal(false);
  };

  const renderFooterContent = () => {
    return (
      <Row justify="space-between">
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <CustomButton type="ghost" onClick={onCancelHandler}>
            {t('adminWorkspace.userManagement.confirmModal.cancel')}
          </CustomButton>
        </Col>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          xs={24}
          sm={24}
          md={6}
          lg={6}
          xl={6}
        >
          <CustomButton onClick={onConfirmHandler}>
            {t('adminWorkspace.userManagement.confirmModal.confirm')}
          </CustomButton>
        </Col>
      </Row>
    );
  };

  return (
    <Modal
      className="user-management__confirm-modal"
      title={modalTitle}
      visible={isShowModal}
      width={570}
      onOk={onConfirmHandler}
      onCancel={onCancelHandler}
      closable={false}
      footer={renderFooterContent()}
    >
      <div>{modalContent}</div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  modalTitle: PropTypes.string,
  modalContent: PropTypes.string,
  isShowModal: PropTypes.bool,
  setIsShowModal: PropTypes.func,
  confirmActionHandler: PropTypes.func,
};

ConfirmModal.defaultProps = {
  modalTitle: '',
  modalContent: '',
  isShowModal: false,
  setIsShowModal: () => {},
  confirmActionHandler: () => {},
};

export default ConfirmModal;
