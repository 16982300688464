import { getAddress } from 'libs/utils/addressUtils';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectProvinces } from 'store/selectors/configSelector';

const useProvinces = () => {
  const provinces = useSelector(selectProvinces);
  const formatedProvinces = useMemo(() => {
    return Object.values(provinces).map((province) => ({
      value: province.id,
      label: province.label,
    }));
  }, [provinces]);
  const isShowProvince = formatedProvinces.length > 0;

  const getAddressWithProvince = useCallback(
    (data, ignores, separator) =>
      getAddress(data, provinces, ignores, separator),
    [provinces]
  );

  return {
    provinces: formatedProvinces,
    isShowProvince,
    getAddressWithProvince,
  };
};

export default useProvinces;
