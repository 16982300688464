import './styles.scss';

import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useAsync from 'hooks/useAsync';
import usePermission from 'hooks/usePermission';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { CONFIG_DATA_TYPES, CONFIG_NAMES } from 'libs/constants/configs';
import { MODULE_SERVICE_RIGHTS } from 'libs/constants/modulerights';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { fapiConfigurationService } from 'services/fapi';
import {
  getCustomerName,
  getCustomerPhone,
  getIsLoading,
} from 'store/selectors/customerSelector';
import { selectUserLanguage } from 'store/selectors/userSelector';

import ServiceConsultingAdvisorImage from '../../assets/img/service_consulting_advisor.webp';
import B2becInfoLayout from '../../components/B2becInfoLayout';
import B2becTable from '../../components/B2becTable';
import BlockTwoCol, { displayTypes } from '../../components/BlockTwoCol';
import CustomButton from '../../components/CustomButton';
import ImageWithFallBack from '../../components/ImageWithFallback';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { SERVICE_PACKAGES_TYPES } from '../../libs/constants';
import useRequestInfoModal from './components/RequestInfoModal';
import ResponsiveTable from './components/ResponsiveTable';
import servicePackagesData from './servicePackagesData';
import tableColumns from './tableColumns';

const getServiceAdvisorLink = async (language) => {
  try {
    const response = await fapiConfigurationService.getConfigsByNames([
      {
        configurationKey: CONFIG_NAMES.Service_Advisor_Link,
        dataTypeDto: CONFIG_DATA_TYPES.OBJECT,
      },
    ]);

    const data = JSON.parse(response[CONFIG_NAMES.Service_Advisor_Link]);

    return data[language];
  } catch (error) {
    console.log('getServiceAdvisorLink: error', error);
  }

  return '';
};

function ServicePackagesPage(props) {
  const { title, breadcrumbs, pageId } = props;

  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);

  useAdobeAnalysis(null, {
    pageId,
    pageName: 'service package',
    pageType: PAGE_TYPES.SERVICE_CASES,
  });

  const { t } = useTranslation();

  const { hasPermission: hasCreateRequestRight } = usePermission(
    MODULE_SERVICE_RIGHTS.CREATE_SEND_SERVICE_REQUEST
  );

  const { isMobile } = useDeviceDetect();

  const { isCustomerLoading, companyName, phoneNumber, language } = useSelector(
    (state) => ({
      isCustomerLoading: getIsLoading(state),
      companyName: getCustomerName(state),
      phoneNumber: getCustomerPhone(state),
      language: selectUserLanguage(state),
    })
  );

  const { execute: executeGetServiceAdvisorLink, value: serviceAdvisorLink } =
    useAsync(getServiceAdvisorLink, false);

  useEffect(() => {
    executeGetServiceAdvisorLink(language);
  }, [executeGetServiceAdvisorLink, language]);

  const { showRequestInfoModal, RequestInfoModal } = useRequestInfoModal();

  const isCustomerLoaded = !isCustomerLoading;

  const onClickLearnMore =
    isCustomerLoaded && hasCreateRequestRight && showRequestInfoModal;

  const renderLearnMoreButton = (serviceType) => (
    <CustomButton
      disabled={!onClickLearnMore}
      onClick={() => onClickLearnMore(serviceType)}
    >
      {t('service.servicePackages.overview.table.buttonTitle')}
    </CustomButton>
  );

  const renderDetailsSection = () => (
    <section className="service-packages__details-wrapper">
      <div className="service-packages__details__upper-section">
        <p className="service-packages__details__description">
          {t('service.servicePackages.introduction')}
        </p>
      </div>
      <div className="service-packages__details__lower-section">
        <div>
          <h2>{t('service.servicePackages.karcherInspect.title')}</h2>
          <p>{t('service.servicePackages.karcherInspect.description')}</p>
          {isMobile && renderLearnMoreButton(SERVICE_PACKAGES_TYPES.INSPECT)}
        </div>
        <div>
          <h2>{t('service.servicePackages.karcherMaintain.title')}</h2>
          <p>{t('service.servicePackages.karcherMaintain.description')}</p>
          {isMobile && renderLearnMoreButton(SERVICE_PACKAGES_TYPES.MAINTAIN)}
        </div>
        <div>
          <h2>{t('service.servicePackages.karcherFullService.title')}</h2>
          <p>{t('service.servicePackages.karcherFullService.description')}</p>
          {isMobile &&
            renderLearnMoreButton(SERVICE_PACKAGES_TYPES.FULLSERVICE)}
        </div>
        <div>
          <h2>{t('service.servicePackages.optionalExtension.title')}</h2>
          <p>{t('service.servicePackages.optionalExtension.description')}</p>
        </div>
      </div>
    </section>
  );

  const renderTableSection = () => (
    <section className="service-packages__overview-wrapper container-full-width-page">
      <div className="service-packages__overview">
        <h3>{t('service.servicePackages.overview.title')}</h3>
        {!isMobile ? (
          <B2becTable
            className="service-packages__table"
            columns={tableColumns(false, onClickLearnMore)}
            dataSource={servicePackagesData}
            rowKey="benefitTranslationKey"
            pagination={false}
          />
        ) : (
          <ResponsiveTable />
        )}
      </div>
    </section>
  );

  const renderAdvisorImage = () => (
    <ImageWithFallBack
      className="service-packages__advisor-image"
      src={ServiceConsultingAdvisorImage}
    />
  );

  const renderAdvisorContent = () => (
    <div className="service-packages__advisor-content">
      <h3 className="service-packages__advisor-content__title">
        {t('service.servicePackages.serviceAdvisor.title')}
      </h3>
      <p className="service-packages__advisor-content__description">
        {t('service.servicePackages.serviceAdvisor.description')}
      </p>
      <a href={serviceAdvisorLink} target="_blank" rel="noopener noreferrer">
        <CustomButton className="service-packages__advisor-content__button">
          {t('service.servicePackages.serviceAdvisor.button')}
        </CustomButton>
      </a>
    </div>
  );

  const renderAdvisorSection = () => (
    <section className="service-packages__advisor-wrapper container-full-width-page">
      {!isMobile && <div className="service-packages__advisor-divider" />}
      <BlockTwoCol
        className="service-packages__advisor"
        left={renderAdvisorImage()}
        right={renderAdvisorContent()}
        type={displayTypes.NORMAL}
      />
    </section>
  );

  const renderContentSection = () => (
    <>
      {renderDetailsSection()}
      {renderTableSection()}
      {renderAdvisorSection()}
      <RequestInfoModal companyName={companyName} phoneNumber={phoneNumber} />
    </>
  );

  return (
    <B2becInfoLayout className="service-packages__wrapper">
      <B2becInfoLayout.Title title={t('service.servicePackages.heading.title')}>
        {t('service.servicePackages.heading.subtitle')}
      </B2becInfoLayout.Title>
      <B2becInfoLayout.Content>
        {renderContentSection()}
      </B2becInfoLayout.Content>
    </B2becInfoLayout>
  );
}

ServicePackagesPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

ServicePackagesPage.defaultProps = {};

export default ServicePackagesPage;
