import './styles.scss';

import { Col, Row } from 'antd';
import AddToCartBtn from 'components/AddToCartBtn';
import ProductCard from 'components/ProductCard';
import useDeviceDetect from 'hooks/useDeviceDetect';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addProductToCart } from 'store/slices/cartSlice';
import {
  sendProductDetail,
  showAddProductToWishlistModal,
} from 'store/slices/wishlistSlice';

import usePermission from '../../../../../hooks/usePermission';
import { MODULE_SHOP_NAVIGATION_RIGHTS } from '../../../../../libs/constants/modulerights';

const ProductCardList = ({
  products,
  comparingProductIds,
  onCompareHandler,
}) => {
  const dispatch = useDispatch();
  const { isLargeScreen } = useDeviceDetect();
  const [canViewListPrice, canAddProductToCart] = usePermission(
    MODULE_SHOP_NAVIGATION_RIGHTS.VIEW_LIST_PRICE,
    MODULE_SHOP_NAVIGATION_RIGHTS.ADD_PRODUCT_TO_SHOPPING_CART
  );

  const onCheckHandler = useCallback(
    (product, event) => {
      const isChecked = event?.target?.checked;

      onCompareHandler(isChecked, product);
    },
    [onCompareHandler]
  );

  const wrapperClass = isLargeScreen
    ? 'product-card-list__wrapper'
    : 'full-width-page-component__wrapper';

  const onAddProductToCart = useCallback(
    (materialNumber) => {
      dispatch(addProductToCart({ materialNumber }));
    },
    [dispatch]
  );

  const handleAddToWishlist = ({ materialName, materialNumber, img }) => {
    dispatch(sendProductDetail({ materialName, materialNumber, img }));
    dispatch(showAddProductToWishlistModal());
  };

  return (
    <div className={wrapperClass}>
      <Row
        gutter={[
          { xs: 0, sm: 0, md: 24, lg: 32, xl: 32, xxl: 32 },
          { xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 32 },
        ]}
        className="product-card-list__container"
      >
        {products.map((product) => (
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={8}
            xxl={6}
            key={product.materialNumber}
          >
            <ProductCard
              name={product.materialName}
              category={product.categoryName}
              img={product.pictureUrl}
              serialNo={product.serialNo}
              materialNumber={product.materialNumber}
              requiresInstruction={product.requiresInstruction}
              listPrice={product.listPrice}
              addToCartBtn={
                <AddToCartBtn
                  handleClick={() => onAddProductToCart(product.materialNumber)}
                />
              }
              comparingProductIds={comparingProductIds}
              onCheckHandler={onCheckHandler}
              isCompareCheckboxDisplayed
              currencyUnit={product?.currencyUnit}
              displayAddToCart={
                product?.displayAddToCart && canAddProductToCart
              }
              shouldDisplayListPrice={
                product?.displayListPrice && canViewListPrice
              }
              onAddToWishlist={handleAddToWishlist}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

ProductCardList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  comparingProductIds: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape({}),
  ]),
  onCompareHandler: PropTypes.func,
};

ProductCardList.defaultProps = {
  comparingProductIds: [],
  onCompareHandler: () => {},
};

export default ProductCardList;
