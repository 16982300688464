import { Col, Row } from 'antd';
import { isValidArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  selectAssignableCountries,
  selectAssignableCountryRolesList,
  selectAssignedRolesByUserId,
} from '../../../store/selectors/roleSelector';
import B2becCustomSelect from '../../B2becCustomSelect';

const AssignRoles = ({ onChange, value }) => {
  const { userId } = useParams();
  const assignableCountries = useSelector(selectAssignableCountries);
  const assignableCountryRoles = useSelector(selectAssignableCountryRolesList);
  const assignedRoles = useSelector(selectAssignedRolesByUserId(userId));
  const firstCountryValue =
    isValidArray(assignableCountries) && assignableCountries[0]?.value;

  const findAssignableRoles = (selectedCountry) => {
    if (selectedCountry) {
      const matchItem = assignableCountryRoles.find(
        (item) => item.country === selectedCountry
      );
      if (matchItem) {
        return matchItem?.roles;
      }
    }
    return [];
  };

  const assignableRoles = useMemo(() => {
    return new Set(
      findAssignableRoles(firstCountryValue)?.map((role) => role.value)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstCountryValue]);

  const filterAssignedRoles = () => {
    return isValidArray(assignedRoles)
      ? assignedRoles
          .filter((assignedRole) => assignableRoles.has(assignedRole?.roleId))
          .map((assignedRole) => assignedRole?.roleId)
      : [];
  };

  const [selectedRoles, setSelectedRoles] = useState(filterAssignedRoles());

  const handleCountryChange = (selectedCountry) => {
    onChange({ country: selectedCountry });
  };

  const handleRolesChange = (newRoles) => {
    onChange({
      country: value?.country || firstCountryValue,
      roles: newRoles,
    });
    setSelectedRoles(newRoles);
  };

  return (
    <Row gutter={24}>
      <Col span={12}>
        <B2becCustomSelect
          value={value?.country || firstCountryValue}
          options={assignableCountries}
          onChange={handleCountryChange}
        />
      </Col>
      <Col span={12}>
        <B2becCustomSelect
          value={selectedRoles}
          options={findAssignableRoles(firstCountryValue)}
          onChange={handleRolesChange}
          mode="multiple"
          optionLabelProp="label"
          style={{ width: '100%' }}
          showArrow
        />
      </Col>
    </Row>
  );
};

AssignRoles.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.shape({
    country: PropTypes.string,
  }),
};

AssignRoles.defaultProps = {
  onChange: () => {},
  value: {},
};

export default AssignRoles;
