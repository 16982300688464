import './styles.scss';

import { Form, Input, Modal, Upload } from 'antd';
import { ReactComponent as PlusIconSVG } from 'assets/icons/plus.svg';
import { ACCEPTED_FILE_EXTENSIONS } from 'libs/constants/upload';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const accpetedFileExtensions = Object.values(
  ACCEPTED_FILE_EXTENSIONS.IMAGES
).join(', ');

const UploadDamagedPicture = ({ form }) => {
  const MAX_TOTAL_SIZE = 20;

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [currentFileList, setCurrentFileList] = useState([]);
  const [uploadFileErrorMsg, setUploadFileErrorMsg] = useState(null);
  const { t } = useTranslation();

  const convertFromByteToMb = (number) => {
    return number / 1024 ** 2;
  };

  const totalFileSize = useMemo(() => {
    const result = currentFileList.reduce(
      (total, file) => total + convertFromByteToMb(file.size),
      0
    );
    return result;
  }, [currentFileList]);

  const uploadButton = (
    <div className="upload-block__button">
      <PlusIconSVG />
      <div>{t('service.upload.button')}</div>
    </div>
  );

  const handleCancel = () => setPreviewVisible(false);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreview = async (file) => {
    setPreviewImage(file.url || file.imageBase64);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const handleValidateFilesSize = (fileList) => {
    const mappedFile = fileList.map(async (file) => {
      let imageBase64 = null;
      if (!file.url && !file.imageBase64) {
        // eslint-disable-next-line no-param-reassign
        imageBase64 = await getBase64(file.originFileObj);
      }

      if (totalFileSize + convertFromByteToMb(file.size) > MAX_TOTAL_SIZE) {
        setUploadFileErrorMsg(t('service.upload.totalSizeLessThan20'));
        return {
          error: true,
          ...file,
        };
      }
      setUploadFileErrorMsg(null);
      const tmpFile = {
        fileName: file.name,
        imageBase64: file.imageBase64 || imageBase64,
        error: false,
        ...file,
      };
      return tmpFile;
    });
    return Promise.all(mappedFile);
  };

  const handleChange = async ({ fileList }) => {
    let newFileList = await handleValidateFilesSize(fileList);
    newFileList = newFileList.filter((file) => !file.error);
    setCurrentFileList(newFileList);
    form.setFieldsValue({
      damagedImages: newFileList,
    });
  };

  const handleBeforeUpload = () => {
    return false;
  };

  return (
    <div className="upload-block__wrapper service-request__layout--shrink">
      <div className="upload-block__title">
        {t('service.selectService.addDamagedPictures')}
      </div>
      <Upload
        listType="picture-card"
        fileList={currentFileList}
        onChange={handleChange}
        beforeUpload={handleBeforeUpload}
        onPreview={handlePreview}
        className="upload-block__content"
        accept={accpetedFileExtensions}
      >
        {uploadButton}
      </Upload>
      <p className="upload-file__error">{uploadFileErrorMsg}</p>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" loading='lazy' style={{ width: '100%' }} src={previewImage} />
      </Modal>
      <Form.Item form={form} name="damagedImages" initialValue={[]} hidden>
        <Input
          className="delivery-content__field-input--disabled"
          data-testid="partner-number"
        />
      </Form.Item>
    </div>
  );
};

UploadDamagedPicture.propTypes = {
  form: PropTypes.shape({}),
};

UploadDamagedPicture.defaultProps = {
  form: {},
};

export default UploadDamagedPicture;
