import { Modal, Upload } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const DamagedPicture = (props) => {
  const { data } = props;
  const [isPreview, setIsPreview] = useState(false);
  const [picturePreviewInfo, setPicturePreviewInfo] = useState({
    previewImage: '',
    previewTitle: '',
  });

  const onPicturePreviewHandler = async (file) => {
    setPicturePreviewInfo({
      previewImage: file?.url || file?.imageBase64,
      previewTitle:
        file?.name || file?.url.substring(file?.url.lastIndexOf('/') + 1),
    });
    setIsPreview(true);
  };

  const onClosePreviewHandler = () => {
    setIsPreview(false);
  };

  return (
    <>
      <Upload
        listType="picture-card"
        fileList={data}
        onPreview={onPicturePreviewHandler}
        className="upload-block__content"
        showUploadList={{
          showRemoveIcon: false,
        }}
      />
      <Modal
        visible={isPreview}
        title={picturePreviewInfo?.previewTitle}
        footer={null}
        onCancel={onClosePreviewHandler}
      >
        <img
          alt="example"
          style={{ width: '100%' }}
          src={picturePreviewInfo?.previewImage}
        />
      </Modal>
    </>
  );
};

DamagedPicture.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
};

export default DamagedPicture;
