import B2bCustomModal from 'components/B2bCustomModal';
import { ReactComponent as CloseIconSVG } from 'assets/icons/close.svg';
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { isEmptyObject } from 'libs/utils/object';
import { replaceCamelToDash } from 'libs/utils/stringUltis';

import './styles.scss';
import { PUNCHOUT_TYPES } from 'libs/constants/punchoutTypes';

const { Text, Paragraph } = Typography;

const PunchoutInfoModal = ({ isVisible, content, ...props }) => {
  const { t } = useTranslation();

  const renderOCIContent = (url) => {
    return (
      <Paragraph
        copyable={{
          text: url,
          tooltips: [
            t('punchoutModal.tooltip.copy'),
            t('punchoutModal.tooltip.copied'),
          ],
        }}
      >
        <Text code className="punchout-value">
          {url}
        </Text>
      </Paragraph>
    );
  };

  const renderCXMLContent = (data) => {
    if (isEmptyObject(data)) return null;
    return Object.entries(data)
      .filter(([key, value]) => key || value)
      .map(([key, value]) => (
        <Row gutter={24} key={key}>
          <Col span={10}>
            <span className="punchout-key">{replaceCamelToDash(key)}:</span>
          </Col>
          <Col span={14}>
            <Paragraph
              copyable={{
                text: value,
                tooltips: [
                  t('punchoutModal.tooltip.copy'),
                  t('punchoutModal.tooltip.copied'),
                ],
              }}
            >
              <Text code className="punchout-value">
                {value}
              </Text>
            </Paragraph>
          </Col>
        </Row>
      ));
  };

  const renderModalContent = () => {
    if (content) {
      const { punchOutType, ...rest } = content;
      if (punchOutType === PUNCHOUT_TYPES.OCI) {
        return renderOCIContent(content?.url);
      }
      return renderCXMLContent({ ...rest });
    }
    return null;
  };

  return (
    <B2bCustomModal
      className="punchout-modal"
      visible={isVisible}
      closeIcon={<CloseIconSVG />}
      title={t('punchoutModal.title')}
      width={500}
      okText={t('punchoutModal.close')}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ className: 'kaercher-button' }}
      {...props}
    >
      {renderModalContent()}
    </B2bCustomModal>
  );
};

export default PunchoutInfoModal;

PunchoutInfoModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  content: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
    punchOutType: PropTypes.string,
  }),
};

PunchoutInfoModal.defaultProps = {
  isVisible: false,
  content: {
    username: '',
    password: '',
    punchOutType: PUNCHOUT_TYPES.OCI,
  },
};
