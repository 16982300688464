import { ReactComponent as WarningIcon } from 'assets/icons/alerticon.svg';
import B2BecLink from 'components/B2BLink';
import CustomButton from 'components/CustomButton';
import { ASYNC_STATUS, SERVICE_ENTRY } from 'libs/constants';
import { formatDate, getFormatType } from 'libs/utils/formatDate';
import { formatSerialNumber } from 'libs/utils/formatSerialNumber';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setSelectedMachineInfo } from 'store/slices/serviceCaseSlice';

import B2becTooltip from '../B2becTooltip';
import ImageWithFallBack from '../ImageWithFallback';
import B2becAvailableServiceButton from './B2becAvailableServiceButton';
import B2becExcludedMessage from './B2becExcludedMessage';
import styles from './B2becProductCard.module.scss';

const B2becProductCard = (props) => {
  const { t } = useTranslation();

  const { orderDate, name, category, imgUrl, materialNumber, serialNumber } =
    props;

  const [isExcludedMachine, setIsExcludedMachine] = useState(false);
  const [isShowMessage, setIsShowMessage] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleValidateSuccess = useCallback(() => {
    dispatch(
      setSelectedMachineInfo({
        id: materialNumber,
        serialNumber: formatSerialNumber(serialNumber),
        isExcludedMachine: false,
        validateStatus: ASYNC_STATUS.SUCCESS,
        serviceEntry: SERVICE_ENTRY.MACHINE,
        faqList: [],
      })
    );

    history.push(linkGenerator('/request-services'));
  }, [dispatch, history, materialNumber, serialNumber]);

  const handleOpenMatchingProduct = useCallback(() => {
    history.push(linkGenerator(`/product/${materialNumber}/all`));
  }, [history, materialNumber]);

  return (
    <div className={styles.container}>
      <div className={styles.cardBody}>
        {isExcludedMachine && isShowMessage && <B2becExcludedMessage />}
        <span className={styles.orderDate}>
          {formatDate(orderDate, getFormatType().default)}
        </span>
        <B2BecLink to={`/product/${materialNumber}`}>
          <ImageWithFallBack height={198} width={198} alt={name} src={imgUrl} />
        </B2BecLink>
        <h4 className={styles.categoryName}>{category}</h4>
        <h3 className={styles.cardItemTitle}>
          <B2BecLink
            className="link-normalize"
            to={`/product/${materialNumber}`}
          >
            <B2becTooltip title={name} content={name} />
          </B2BecLink>
        </h3>
        <div className="card-item__material-number--wrapper">
          <div className="card-item__item-number--wrapper">
            <B2BecLink
              className="link-normalize fadeIn-1"
              to={`/product/${materialNumber}`}
            >
              <span className={styles.cardItemNumber}>
                {`${t('productCard.itemNumber')} `}
                {materialNumber}
              </span>
            </B2BecLink>
            {isExcludedMachine && (
              <button
                className={styles.toggleMessageButton}
                onClick={() => setIsShowMessage((isShow) => !isShow)}
                type="button"
              >
                <WarningIcon />
              </button>
            )}
          </div>
          <p>
            {serialNumber && (
              <>
                {`${t('productCard.serialNumber')} `}
                {formatSerialNumber(serialNumber)}
              </>
            )}
          </p>
        </div>
        <div className={styles.cardButtonWrapper}>
          <CustomButton onClick={handleOpenMatchingProduct}>
            {t('myMachines.matchingProduct')}
          </CustomButton>
          <B2becAvailableServiceButton
            materialNumber={materialNumber}
            onError={() => setIsExcludedMachine(true)}
            onSuccess={handleValidateSuccess}
          >
            {t('service.selectMachine.availableService')}
          </B2becAvailableServiceButton>
        </div>
      </div>
    </div>
  );
};

B2becProductCard.propTypes = {
  orderDate: PropTypes.string,
  name: PropTypes.string,
  imgUrl: PropTypes.string,
  category: PropTypes.string,
  materialNumber: PropTypes.string,
  serialNumber: PropTypes.string,
};

B2becProductCard.defaultProps = {
  orderDate: '',
  name: '',
  imgUrl: '',
  category: '',
  materialNumber: '',
  serialNumber: '',
};

export default B2becProductCard;
