import { Layout } from 'antd';
import ScrollToTop from 'components/ScrollToTop';
import NotAuthorizedPage from 'pages/NotAuthorizedPage';
import PublicPageNotFound from 'pages/PublicPageNotFound';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { generateRoutes } from 'routes/routes';

import ProtectedRoute from './ProtectedRoute';

const { privateRoutes, publicRoutes } = generateRoutes();

const AppRouter = () => {
  return (
    <Layout style={{ backgroundColor: 'transparent', minHeight: '100vh' }}>
      <ScrollToTop />
      <Switch>
        {privateRoutes.map((route) => {
          return <ProtectedRoute key={route.path} {...route} />;
        })}

        {publicRoutes.map(
          ({ path, component: Component, title, pageID, ...rest }) => (
            <Route
              key={path}
              path={path}
              {...rest}
              render={() => <Component title={title} pageId={pageID} />}
            />
          )
        )}
        <Route path="/403">
          <NotAuthorizedPage title="Not Authorized Page" pageId="403" />;
        </Route>
        <Route>
          <PublicPageNotFound title="404 Not found" pageId="404" />
        </Route>
      </Switch>
    </Layout>
  );
};

export default AppRouter;
