import './styles.scss';

import B2becTranslation from 'components/B2becTranslation';
import {
  USER_ACCOUNT_STATUS,
  USER_STATUS_CLASS_NAME,
} from 'libs/constants/user';
import { formatDate, getFormatType } from 'libs/utils/formatDate';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const renderColumns = (
  isLoading,
  onCellClick,
  hasViewUserDetailPermission
) => [
  {
    title: () => (
      <div>
        <B2becTranslation value="userManagement.companyName" />
      </div>
    ),
    dataIndex: 'companyName',
    width: '8%',
    sortDirections: ['ascend', 'descend', 'ascend'],
    defaultSortOrder: 'ascend',
    sorter: true,
    showSorterTooltip: false,
    onCell: ({ userId, invitationStatus }) => ({
      onClick: () =>
        hasViewUserDetailPermission &&
        !isLoading &&
        onCellClick(userId, invitationStatus),
    }),
    render: (text) =>
      isLoading ? (
        <Skeleton />
      ) : (
        <span className="font-weight-medium">{text}</span>
      ),
  },
  {
    title: <B2becTranslation value="userManagement.firstName" />,
    dataIndex: 'firstName',
    width: '8%',
    onCell: ({ userId, invitationStatus }) => ({
      onClick: () =>
        hasViewUserDetailPermission &&
        !isLoading &&
        onCellClick(userId, invitationStatus),
    }),
    render: (text) => (isLoading ? <Skeleton /> : <span>{text}</span>),
  },
  {
    title: <B2becTranslation value="userManagement.lastName" />,
    dataIndex: 'lastName',
    width: '8%',
    onCell: ({ userId, invitationStatus }) => ({
      onClick: () =>
        hasViewUserDetailPermission &&
        !isLoading &&
        onCellClick(userId, invitationStatus),
    }),
    render: (text) => (isLoading ? <Skeleton /> : <span>{text}</span>),
  },
  {
    title: <B2becTranslation value="userManagement.userRoles" />,
    width: '8%',
    dataIndex: 'userPolicy',
    onCell: ({ userId, invitationStatus }) => ({
      onClick: () =>
        hasViewUserDetailPermission &&
        !isLoading &&
        onCellClick(userId, invitationStatus),
    }),
    render: (text) =>
      isLoading ? (
        <Skeleton />
      ) : (
        <span className="font-weight-medium">{text}</span>
      ),
  },
  {
    title: <B2becTranslation value="userManagement.email" />,
    dataIndex: 'email',
    width: '10%',
    onCell: ({ userId, invitationStatus }) => ({
      onClick: () =>
        hasViewUserDetailPermission &&
        !isLoading &&
        onCellClick(userId, invitationStatus),
    }),
    render: (text) =>
      isLoading ? (
        <Skeleton />
      ) : (
        <span className="font-weight-medium">{text}</span>
      ),
  },
  {
    title: <B2becTranslation value="userManagement.sapCustomerNumber" />,
    dataIndex: 'sapCustomerNumber',
    width: '8%',
    onCell: ({ userId, invitationStatus }) => ({
      onClick: () =>
        hasViewUserDetailPermission &&
        !isLoading &&
        onCellClick(userId, invitationStatus),
    }),
    render: (text) =>
      isLoading ? (
        <Skeleton />
      ) : (
        <span className="font-weight-medium">{text}</span>
      ),
  },
  {
    title: <B2becTranslation value="userManagement.lastLogin" />,
    dataIndex: 'lastLogin',
    width: '8%',
    onCell: ({ userId, invitationStatus }) => ({
      onClick: () =>
        hasViewUserDetailPermission &&
        !isLoading &&
        onCellClick(userId, invitationStatus),
    }),
    render: (text) =>
      isLoading ? (
        <Skeleton />
      ) : (
        <span className="user-management__user-list__last-login font-weight-medium">
          {formatDate(text, getFormatType().default)}
          <br />
          {formatDate(text, getFormatType().timeDetail)}
        </span>
      ),
  },
  {
    title: <B2becTranslation value="userManagement.status" />,
    width: '6%',
    onCell: ({ userId, invitationStatus }) => ({
      onClick: () =>
        hasViewUserDetailPermission &&
        !isLoading &&
        onCellClick(userId, invitationStatus),
    }),
    render: (_, { status, invitationStatus }) => {
      /* if INVITATION STATUS is available, use INVITATION STATUS
       * else use normal STATUS
       * if STATUS is not available or STATUS === 'Open' (value: null, unauthorized, open)
       * set it to be 'Unauthorized'
       */
      let userStatus = invitationStatus || status;

      if (!status || (status && status === USER_ACCOUNT_STATUS.OPEN)) {
        userStatus = USER_ACCOUNT_STATUS.UN_AUTHORIZED;
      }

      return isLoading ? (
        <Skeleton />
      ) : (
        <div className={`user-status ${USER_STATUS_CLASS_NAME[userStatus]}`}>
          <B2becTranslation value={`userManagement.user${userStatus}`} />
        </div>
      );
    },
  },
];
