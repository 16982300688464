import { Col, Row } from 'antd';
import MaintenanceFuturetechImage from 'assets/img/kft_maintenance.webp';
import RepairFuturetechImage from 'assets/img/kft_repair.webp';
import ServicePartnerContactFuturetechImage from 'assets/img/kft_service.webp';
import MaintenanceKaercherImage from 'assets/img/maintenance.webp';
import RepairKaercherImage from 'assets/img/repair.webp';
import ServicePartnerContactKaercherImage from 'assets/img/servicepartner_kontakt.webp';
import ImageWithFallBack from 'components/ImageWithFallback';
import usePermission from 'hooks/usePermission';
import { SERVICE_ENTRY } from 'libs/constants';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectIsFuturetech } from 'store/selectors/configSelector';
import { selectServicePackagePageByLanguage } from 'store/selectors/userSelector';
import { setSelectedMachineInfo } from 'store/slices/serviceCaseSlice';

import PermissionWrapper from '../../../HOCs/permissionWrapper';
import {
  MODULE_SERVICE_RIGHTS,
  MODULE_SHOP_NAVIGATION_RIGHTS,
} from '../../../libs/constants/modulerights';
import styles from './ServicesMenu.module.scss';

const imagePaths = {
  karcher: {
    maintenance: MaintenanceKaercherImage,
    repair: RepairKaercherImage,
    servicePartnerContact: ServicePartnerContactKaercherImage,
  },
  futuretech: {
    maintenance: MaintenanceFuturetechImage,
    repair: RepairFuturetechImage,
    servicePartnerContact: ServicePartnerContactFuturetechImage,
  },
};

const ServiceMenu = ({ setShowSubmenu }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [hasCreateRepairRight, hasCreateMaintenanceRight] = usePermission(
    MODULE_SERVICE_RIGHTS.CREATE_REPAIR_SERVICE_CASE,
    MODULE_SERVICE_RIGHTS.CREATE_MAINTENANCE_SERVICE_CASE
  );

  const shouldHideCreateServiceCaseSession =
    !hasCreateRepairRight && !hasCreateMaintenanceRight;

  const productMenuRef = useRef(null);

  const servicePackagePageByLanguage = useSelector(
    selectServicePackagePageByLanguage
  );
  const isFuturetech = useSelector(selectIsFuturetech);
  const images = imagePaths[isFuturetech ? 'futuretech' : 'karcher'];

  const handleClickOutside = useCallback(
    (event) => {
      if (
        productMenuRef.current &&
        !productMenuRef.current.contains(event.target)
      ) {
        setShowSubmenu(false);
      }
    },
    [setShowSubmenu]
  );

  const handleServiceItemClick = useCallback(
    (serviceEntry) => {
      dispatch(
        setSelectedMachineInfo({
          serviceEntry,
          isChangedService: true,
        })
      );

      setShowSubmenu(false);
      history.push(linkGenerator('/request-services'));
    },
    [dispatch, history, setShowSubmenu]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [handleClickOutside]);

  const renderServicePackageBlock = () => {
    const content = (
      <>
        <ImageWithFallBack src={images.servicePartnerContact} />
        <span className={styles['service-link__title']}>
          {t('service.headerMenu.servicePackages')}
        </span>
      </>
    );

    return servicePackagePageByLanguage ? (
      <div
        role="button"
        tabIndex="0"
        onKeyDown={(e) => e.preventDefault()}
        className={styles['service-link']}
        onClick={() => {
          setShowSubmenu(false);
        }}
      >
        <a href={servicePackagePageByLanguage} target="noreferrer">
          {content}
        </a>
      </div>
    ) : (
      <div
        className={styles['service-link']}
        onClick={() => {
          setShowSubmenu(false);
          history.push(linkGenerator('/service/service-packages'));
        }}
      >
        {content}
      </div>
    );
  };

  return (
    <div className="header-submenu fadeInDown" ref={productMenuRef}>
      <div className="header-submenu__container">
        <PermissionWrapper
          permission={
            MODULE_SHOP_NAVIGATION_RIGHTS.VIEW_SERVICE_PACKAGE_INFORMATION
          }
        >
          <Row gutter={10} justify="center">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="header-submenu__item"
            >
              {renderServicePackageBlock()}
            </Col>
          </Row>
        </PermissionWrapper>
        {shouldHideCreateServiceCaseSession ? null : (
          <>
            <Row>
              <Col span={24} className="header-submenu__title">
                <h3>{t('createServiceRequest')}</h3>
              </Col>
            </Row>
            <Row gutter={10}>
              <PermissionWrapper
                permission={MODULE_SERVICE_RIGHTS.CREATE_REPAIR_SERVICE_CASE}
              >
                <Col
                  xs={12}
                  sm={8}
                  md={6}
                  lg={6}
                  xl={6}
                  className="header-submenu__item"
                >
                  <div
                    className={styles['service-link']}
                    onClick={() => {
                      handleServiceItemClick(SERVICE_ENTRY.REPAIR);
                    }}
                  >
                    <ImageWithFallBack src={images.repair} />
                    <span className={styles['service-link__title']}>
                      {t('service.headerMenu.requestRepair')}
                    </span>
                  </div>
                </Col>
              </PermissionWrapper>
              <PermissionWrapper
                permission={
                  MODULE_SERVICE_RIGHTS.CREATE_MAINTENANCE_SERVICE_CASE
                }
              >
                <Col
                  xs={12}
                  sm={8}
                  md={6}
                  lg={6}
                  xl={6}
                  className="header-submenu__item"
                >
                  <div
                    className={styles['service-link']}
                    onClick={() => {
                      handleServiceItemClick(SERVICE_ENTRY.MAINTENANCE);
                    }}
                  >
                    <ImageWithFallBack src={images.maintenance} />
                    <span className={styles['service-link__title']}>
                      {t('service.headerMenu.requestMaintenance')}
                    </span>
                  </div>
                </Col>
              </PermissionWrapper>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

ServiceMenu.propTypes = {
  setShowSubmenu: PropTypes.func,
};

ServiceMenu.defaultProps = {
  setShowSubmenu: () => {},
};

export default ServiceMenu;
