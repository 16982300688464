import { isValidArray } from 'libs/utils/array';
import { useSelector } from 'react-redux';

import { selectUserRightList } from '../store/selectors/roleSelector';

export default function usePermission(permission, ...restPermissions) {
  const userRights = useSelector(selectUserRightList);
  const mappedRights = new Set(userRights);

  const checkPermission = (right) => mappedRights.has(right);

  const checkMultiplePermissions = (rights) => {
    if (isValidArray(rights)) {
      return rights.map(checkPermission);
    }
    return [];
  };

  // Noted: implement the `restPermissions` as the temporarily approach
  // to ignore the existed implementations. This function will be refactored.
  if (isValidArray(restPermissions) || isValidArray(permission)) {
    const permissions = [permission, ...restPermissions];
    const verifiedPermission = checkMultiplePermissions(permissions);
    return [...verifiedPermission, checkPermission, checkMultiplePermissions];
  }

  return {
    hasPermission: checkPermission(permission),
    checkPermission,
    checkMultiplePermissions,
  };
}
