import './styles.scss';

import { Col, Divider, Grid, Row } from 'antd';
import { B2bTechnicalUserForm } from 'components/B2bTechnicalUser';
import CustomButton from 'components/CustomButton';
import NotFound from 'components/NotFound';
import { NotificationManager } from 'components/Notification';
import { useBreadcrumbs, useDocumentTitle } from 'hooks';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import { USER_FORM_TYPES } from 'libs/constants';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { isValidArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { adminFapiTechnicalUser, fapiRoleService } from 'services/adminFapi';
import { selectUserId } from 'store/selectors/authenticationSelectors';
import { selectAssignedRolesByUserId } from 'store/selectors/roleSelector';
import {
  selectTechnicalDetailsData,
  selectTechnicalDetailsError,
  selectTechnicalDetailsIsUpdating,
  selectTechnicalDetailsLoading,
} from 'store/selectors/technicalSelector';
import {
  clearAssignedRolesByUserId,
  getAssignableRolesByUserId,
  getAssignedRolesByUserId,
} from 'store/slices/roleSlice';
import { getDetails, updateDetails } from 'store/slices/technicalSlice';

import B2becInfoLayout from '../../../../components/B2becInfoLayout';
import PermissionWrapper from '../../../../HOCs/permissionWrapper';
import { MODULE_USER_MANAGEMENT_RIGHTS } from '../../../../libs/constants/modulerights';
import DeleteUserSection from './components/DeleteUserSection';
import InvoiceAddressSection from './components/InvoiceAddressSection';
import LoggingHistorySection from './components/LoggingHistorySection';
import SubheadingSection from './components/SubheadingSection';
import TechnicalUserInformationSection from './components/TechnicalUserInformation';
import UserStatusSection from './components/UserStatusSection';
import TechnicalUserSetPassword from './TechnicalUserSetPassword';

const onAssignRoles = async (userId, assignedRoles) => {
  try {
    const result = [];
    for (const { country, roles } of assignedRoles) {
      for (const role of roles) {
        result.push({ country, role });
      }
    }

    const payload = {
      userId,
      roles: result,
    };

    await fapiRoleService.assignRolesToUser(payload);
  } catch (error) {
    console.error('error when assigning roles to user', error?.message);
    NotificationManager.success({
      message: 'notification.error.assignRoles',
    });
  }
};

function TechnicalUserDetailsPages(props) {
  const { title, breadcrumbs, pageId } = props;
  const { userId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [isShowEditPassword, setIsShowEditPassword] = useState(false);
  const userDetails = useSelector(selectTechnicalDetailsData);
  const isLoading = useSelector(selectTechnicalDetailsLoading);
  const isUpdating = useSelector(selectTechnicalDetailsIsUpdating);
  const error = useSelector(selectTechnicalDetailsError);
  const karcherAdminId = useSelector(selectUserId);
  const isNotFound = error === 'NOT_FOUND';
  const isUpdateFailed = error === 'UPDATE_FAILED';
  const { setTitleData, setIsTitleTranslated } = useDocumentTitle(title);
  const [setBreadcrumbsData] = useBreadcrumbs(breadcrumbs);
  const { displayName } = userDetails;
  const assignedRoles = useSelector(selectAssignedRolesByUserId(userId));

  const { setPageInfoData } = useAdobeAnalysis();

  useEffect(() => {
    dispatch(getDetails(userId));
    dispatch(getAssignedRolesByUserId(userId));

    return () => {
      dispatch(clearAssignedRolesByUserId(userId));
    };
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(getAssignableRolesByUserId(karcherAdminId));
  }, [dispatch, karcherAdminId]);

  useEffect(() => {
    setIsEdit(false);
  }, [userDetails]);

  useEffect(() => {
    if (displayName) {
      setIsTitleTranslated(true);
      setTitleData([displayName]);
      setBreadcrumbsData([
        {
          titleData: [displayName],
          pathData: [`technical/${userId}`],
        },
      ]);

      setPageInfoData({
        pageName: `technical user details:${displayName}`,
        pageId,
        pageType: PAGE_TYPES.USER_MANAGEMENT,
      });
    }
  }, [
    displayName,
    pageId,
    setBreadcrumbsData,
    setIsTitleTranslated,
    setTitleData,
    userId,
    setPageInfoData,
  ]);

  const { lg } = Grid.useBreakpoint();

  const handleUpdate = useCallback(
    (updatedData) => {
      const { countryRoles: roles, ...data } = updatedData;
      if (isValidArray(roles)) {
        onAssignRoles(userId, roles);
      }

      dispatch(updateDetails({ userId, ...data }));
    },
    [dispatch, userId]
  );

  const handleCancelSetPassword = useCallback(() => {
    setIsShowEditPassword(false);
  }, []);

  const renderUserInformationSection = () => (
    <section className="user-details__user-info__wrapper">
      <div className="user-details__user-info__title">
        {t(
          'adminWorkspace.userManagement.userDetails.userInformationSection.title'
        )}
      </div>
      {isEdit ? (
        <B2bTechnicalUserForm
          errorMessage={isUpdateFailed}
          type={USER_FORM_TYPES.EDIT}
          initialValues={userDetails}
          isUpdating={isUpdating}
          onCancel={() => setIsEdit(false)}
          onFinish={handleUpdate}
        />
      ) : (
        <TechnicalUserInformationSection
          isLoading={isLoading}
          name={userDetails.displayName}
          email={userDetails.email}
          phoneNumber={userDetails.phoneNumber}
          userPolicy={userDetails.userPolicy}
          countryId={userDetails.countryId}
          preferredLanguageCode={userDetails.language}
          salutation={userDetails.salutation}
          assignedRoles={assignedRoles}
          onEdit={() => setIsEdit(true)}
        />
      )}
      <PermissionWrapper
        permission={MODULE_USER_MANAGEMENT_RIGHTS.RESET_USER_PASSWORD}
      >
        <div className="profile__main-block mt-6">
          <div className="profile__main-block__label">
            {t('myProfile.password')}
          </div>
        </div>
        {isShowEditPassword ? (
          <TechnicalUserSetPassword
            userId={userDetails.userId}
            username={userDetails.username}
            onCancel={handleCancelSetPassword}
          />
        ) : (
          <CustomButton
            className="btn btn--submit mt-4"
            type="primary"
            onClick={() => setIsShowEditPassword(true)}
            disabled={isLoading}
          >
            {t('setPassword.title')}
          </CustomButton>
        )}
      </PermissionWrapper>
    </section>
  );

  const renderInvoiceAddressSection = () => (
    <InvoiceAddressSection
      isLoading={isLoading}
      name={userDetails.customerAddress?.name}
      name2={userDetails.customerAddress?.name2}
      street={userDetails.customerAddress?.street}
      houseNumber={userDetails.customerAddress?.houseNumber}
      zip={userDetails.customerAddress?.zip}
      region={userDetails.customerAddress?.region}
      city={userDetails.customerAddress?.city}
      country={userDetails.customerAddress?.country}
      customerNumber={userDetails.customerAddress?.partnerNumber}
    />
  );

  const renderUserStatusSection = () => (
    <UserStatusSection isLoading={isLoading} userStatus={userDetails.status} />
  );

  const renderLoggingHistorySection = () => (
    <LoggingHistorySection
      isLoading={isLoading}
      createdOn={userDetails.createdAt}
      lastLogin={userDetails.lastLogin}
      invitedOn={userDetails.invitedOn}
      revokedOn={userDetails.revokedOn}
    />
  );

  const renderMobileView = () => (
    <article>
      {renderUserInformationSection()}
      <Divider className="user-details__divider" />
      {renderInvoiceAddressSection()}
      <Divider className="user-details__divider" />
      {renderUserStatusSection()}
      {renderLoggingHistorySection()}
      <PermissionWrapper permission={MODULE_USER_MANAGEMENT_RIGHTS.DELETE_USER}>
        <DeleteUserSection
          className="user-details__delete-user__wrapper"
          isLoading={isLoading}
          userId={userId}
          handleDeleteUser={adminFapiTechnicalUser.deleteUserTechnical}
          redirectUrl="/user-management/technical"
        />
      </PermissionWrapper>
    </article>
  );

  const renderLeftColumnDesktop = () => (
    <article className="user-details__content__column--left">
      {renderUserInformationSection()}
      <Divider className="user-details__divider" />
      {renderInvoiceAddressSection()}
    </article>
  );

  const renderRightColumnDesktop = () => (
    <article className="user-details__content__column--right">
      {renderUserStatusSection()}
      {renderLoggingHistorySection()}
      <PermissionWrapper permission={MODULE_USER_MANAGEMENT_RIGHTS.DELETE_USER}>
        <DeleteUserSection
          className="user-details__delete-user__wrapper"
          isLoading={isLoading}
          userId={userId}
          handleDeleteUser={adminFapiTechnicalUser.deleteUserTechnical}
          redirectUrl="/user-management/technical"
        />
      </PermissionWrapper>
    </article>
  );

  const renderContentSection = () => (
    <section className="user-details__content__wrapper">
      {!lg ? (
        renderMobileView()
      ) : (
        <Row>
          <Col span={16}>{renderLeftColumnDesktop()}</Col>
          <Col span={8}>{renderRightColumnDesktop()}</Col>
        </Row>
      )}
    </section>
  );

  if (isNotFound) {
    return <NotFound />;
  }

  return (
    <B2becInfoLayout className="user-details__wrapper">
      <B2becInfoLayout.Title
        title={isLoading ? <Skeleton width={260} /> : userDetails.displayName}
      >
        <SubheadingSection
          isLoading={isLoading}
          customerNumber={userDetails.customerNumber}
          userStatus={userDetails.status}
          email={userDetails.email}
        />
      </B2becInfoLayout.Title>
      <B2becInfoLayout.Content>
        {renderContentSection()}
      </B2becInfoLayout.Content>
    </B2becInfoLayout>
  );
}

TechnicalUserDetailsPages.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default TechnicalUserDetailsPages;
