import { Modal } from 'antd';
import ItemNumberExampleImage from 'assets/img/item-number-example.webp';
import SerialNumberExampleImage from 'assets/img/serial-number-example.webp';
import ImageWithFallBack from 'components/ImageWithFallback';
import DangerouslySetInnerElement from 'components/SpecialSymbolElement';
import { useDeviceDetect } from 'hooks';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './HowToFindItemNumber.module.scss';

const IMAGES = {
  itemNumberInfo: ItemNumberExampleImage,
  serialNumberInfo: SerialNumberExampleImage,
};

const HowToFindItemNumber = (props) => {
  const { visible, onToggle, type } = props;
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();

  const title = t(`service.selectMachine.${type}.title`);
  const description = t(`service.selectMachine.${type}.description`);
  const image = IMAGES[type];

  if (!visible) {
    return <div />;
  }

  if (isMobile) {
    return (
      <Modal
        title={title}
        visible
        onCancel={onToggle}
        footer={null}
        wrapClassName="info-modal--wrapper"
      >
        <DangerouslySetInnerElement rawHTML={description} />
        <div>
          <ImageWithFallBack src={image} />
        </div>
      </Modal>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <ImageWithFallBack src={image} />
      </div>
      <div className={styles.content}>
        <span className={styles.heading}>{title}</span>
        <p>{description}</p>
      </div>
    </div>
  );
};

HowToFindItemNumber.defaultProps = {
  visible: false,
  type: 'itemNumberInfo',
};

HowToFindItemNumber.propTypes = {
  onToggle: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  type: PropTypes.oneOf(['itemNumberInfo', 'serialNumberInfo']),
};

export default HowToFindItemNumber;
