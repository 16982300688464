import {
  ConfigurationsIcon,
  ConfigurationsWhiteIcon,
  ContactFilledIcon,
  ContactIcon,
  HeartFilledSidebarIcon,
  HeartSidebarIcon,
  MachinesFilledIcon,
  MachinesIcon,
  OrdersFilledIcon,
  OrdersIcon,
  OverviewFilledIcon,
  OverviewIcon,
  PromotionFilledIcon,
  PromotionsIcon,
  RentalFilledSidebarIcon,
  RentalSidebarIcon,
  RolesManagementIcon,
  ServiceCaseManagementIcon,
  TranslationFilledIcon,
  TranslationIcon,
  UsersFilledIcon,
  UsersIcon,
  VouchersFilledIcon,
  VouchersIcon,
} from 'components/Icons';
import { LINK_NAMES } from 'libs/constants/adobeAnalytics';
import { PHRASE_PROJECT_URL } from 'libs/constants/lang';
import {
  MODULE_ADMINISTRATION_RIGHTS,
  MODULE_MACHINE_RIGHTS,
  MODULE_ORDER_RIGHTS,
  MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS,
  MODULE_SERVICE_RIGHTS,
} from 'libs/constants/modulerights';
import React from 'react';

const adminMenu = [
  {
    key: 'admin-workspace',
    title: 'sidebar.admin.overview',
    NormalIcon: <OverviewIcon className="my-workspace__icons--normal" />,
    SelectedIcon: (
      <OverviewFilledIcon className="my-workspace__icons--selected" />
    ),
    path: '/admin-workspace',
  },
  {
    key: 'admin-translation',
    title: 'sidebar.admin.translationAdministration',
    NormalIcon: <TranslationIcon className="my-workspace__icons--normal" />,
    SelectedIcon: (
      <TranslationFilledIcon className="my-workspace__icons--selected" />
    ),
    path: PHRASE_PROJECT_URL,
    isExternal: true,
  },
  {
    key: 'voucher-management',
    title: 'sidebar.admin.voucherManagement',
    NormalIcon: <VouchersIcon className="my-workspace__icons--normal" />,
    SelectedIcon: (
      <VouchersFilledIcon className="my-workspace__icons--selected" />
    ),
    path: '/voucher-management',
    requirePermission:
      MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS.VIEW_VOUCHER,
  },
  {
    key: 'promotion-management',
    title: 'sidebar.admin.promotionManagement',
    NormalIcon: <PromotionsIcon className="my-workspace__icons--normal" />,
    SelectedIcon: (
      <PromotionFilledIcon className="my-workspace__icons--selected" />
    ),
    path: '/promotion-management',
    requirePermission:
      MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS.UPDATE_PROMOTION,
  },
  {
    key: 'contact-reasons',
    title: 'sidebar.admin.contactReasonManagement',
    NormalIcon: <ContactIcon className="my-workspace__icons--normal" />,
    SelectedIcon: (
      <ContactFilledIcon className="my-workspace__icons--selected" />
    ),
    path: '/contact-reasons',
    requirePermission: MODULE_ADMINISTRATION_RIGHTS.CRUD_CONTACT_REASON,
  },
  {
    key: 'user-management',
    title: 'sidebar.admin.userManagement',
    NormalIcon: <UsersIcon className="my-workspace__icons--normal" />,
    SelectedIcon: <UsersFilledIcon className="my-workspace__icons--selected" />,
    path: '/user-management',
  },
  {
    key: 'rental-management',
    title: 'sidebar.admin.rentalManagement',
    NormalIcon: <RentalSidebarIcon className="my-workspace__icons--normal" />,
    SelectedIcon: (
      <RentalFilledSidebarIcon className="my-workspace__icons--selected" />
    ),
    path: '/rental-management',
  },
  {
    key: 'configuration-management',
    title: 'sidebar.admin.configurations',
    NormalIcon: <ConfigurationsIcon className="my-workspace__icons--normal" />,
    SelectedIcon: (
      <ConfigurationsWhiteIcon className="my-workspace__icons--selected" />
    ),
    path: '/configuration-management',
    requirePermission: MODULE_ADMINISTRATION_RIGHTS.VIEW_CONFIGURATION,
  },
  {
    key: 'service-management',
    title: 'sidebar.admin.serviceCaseEntry',
    NormalIcon: (
      <ServiceCaseManagementIcon className="my-workspace__icons--normal " />
    ),
    SelectedIcon: (
      <ServiceCaseManagementIcon className="my-workspace__icons--selected" />
    ),
    path: '/service-management',
  },
  {
    key: 'roles-management',
    title: 'sidebar.admin.roleManagement',
    NormalIcon: (
      <RolesManagementIcon className="my-workspace__icons--normal " />
    ),
    SelectedIcon: (
      <RolesManagementIcon className="my-workspace__icons--selected" />
    ),
    path: '/roles-management',
    requirePermission: MODULE_ADMINISTRATION_RIGHTS.ROLE_RIGHT,
  },
  {
    key: 'product-management',
    title: 'sidebar.admin.productManagement',
    NormalIcon: <ConfigurationsIcon className="my-workspace__icons--normal" />,
    SelectedIcon: (
      <ConfigurationsWhiteIcon className="my-workspace__icons--selected" />
    ),
    path: '/product-management',
    requirePermission:
      MODULE_ADMINISTRATION_RIGHTS.VIEW_PRODUCT_PORFOLIO_MANAGEMENT,
  },
  {
    key: 'support-area',
    title: 'sidebar.admin.supportArea',
    NormalIcon: <ConfigurationsIcon className="my-workspace__icons--normal" />,
    SelectedIcon: (
      <ConfigurationsWhiteIcon className="my-workspace__icons--selected" />
    ),
    path: '/support-area',
    requirePermission: MODULE_ADMINISTRATION_RIGHTS.SUPPORT_AREA,
  },
];

const clientMenu = [
  {
    key: 'my-workspace',
    title: 'sidebar.myWorkspace',
    NormalIcon: <OverviewIcon className="my-workspace__icons--normal" />,
    SelectedIcon: (
      <OverviewFilledIcon className="my-workspace__icons--selected" />
    ),
    linkName: LINK_NAMES.SIDEBAR_NAVIGATION.MY_WORKSPACE,
    path: '/my-workspace',
  },
  {
    key: 'my-machines',
    title: 'sidebar.myMachine',
    NormalIcon: <MachinesIcon className="my-workspace__icons--normal" />,
    SelectedIcon: (
      <MachinesFilledIcon className="my-workspace__icons--selected" />
    ),
    linkName: LINK_NAMES.SIDEBAR_NAVIGATION.MY_MACHINES,
    path: '/my-machines',
    requirePermission: MODULE_MACHINE_RIGHTS.VIEW_MACHINE_LIST,
  },
  {
    key: 'rental',
    title: 'sidebar.rental',
    NormalIcon: <RentalSidebarIcon className="my-workspace__icons--normal" />,
    SelectedIcon: (
      <RentalFilledSidebarIcon className="my-workspace__icons--selected" />
    ),
    linkName: LINK_NAMES.SIDEBAR_NAVIGATION.RENTAL,
    path: '/rental',
  },
  {
    key: 'orders',
    title: 'sidebar.orders',
    NormalIcon: <OrdersIcon className="my-workspace__icons--normal" />,
    SelectedIcon: (
      <OrdersFilledIcon className="my-workspace__icons--selected" />
    ),
    linkName: LINK_NAMES.SIDEBAR_NAVIGATION.ORDERS,
    path: '/orders',
    requirePermission: MODULE_ORDER_RIGHTS.VIEW_ORDER_HISTORY,
  },
  {
    key: 'wishlists',
    title: 'sidebar.wishlists',
    NormalIcon: (
      <HeartSidebarIcon
        height={16}
        width={16}
        className="my-workspace__icons--normal"
      />
    ),
    SelectedIcon: (
      <HeartFilledSidebarIcon
        height={16}
        width={16}
        className="my-workspace__icons--selected"
      />
    ),
    linkName: LINK_NAMES.SIDEBAR_NAVIGATION.WISHLISTS,
    path: '/wishlists',
  },
  {
    key: 'contact-form',
    title: 'sidebar.contact',
    NormalIcon: <ContactIcon className="my-workspace__icons--normal" />,
    SelectedIcon: (
      <ContactFilledIcon className="my-workspace__icons--selected" />
    ),
    linkName: LINK_NAMES.SIDEBAR_NAVIGATION.CONTACT,
    path: '/contact-form',
    requirePermission: MODULE_SERVICE_RIGHTS.VIEW_CONTACT_FORM,
  },
];

export { adminMenu, clientMenu };
