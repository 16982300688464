import { useGetCountryName } from 'hooks';
import useProvinces from 'hooks/useAddress';
import React from 'react';

import styles from './style.module.scss';

export const DeliveryAddress = (props) => {
  const { data, ignores = [] } = props;
  const { getAddressWithProvince } = useProvinces();
  const { country } = data;
  const { name, address, location, street4 } = getAddressWithProvince(
    data,
    ignores
  );
  const translatedCountryName = useGetCountryName(country);

  return (
    <div className={styles.deliveryAddress}>
      <strong>{name}</strong>
      <span>{address}</span>
      <span>{street4}</span>
      <span>{location}</span>
      <strong>{translatedCountryName}</strong>
    </div>
  );
};
