import B2becInfoLayout from 'components/B2becInfoLayout';
import { useBreadcrumbs, useDocumentTitle } from 'hooks';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { isRoleLoading, selectRoleDetails } from 'store/selectors/roleSelector';
import { updateRoleDetail } from 'store/slices/roleSlice';

import RoleForm from '../RoleForm';

export const getRealParentRole = (hierarchy, roleId) => {
  try {
    const hierarchies = hierarchy.split('/');
    return hierarchies.filter((id) => id !== roleId).join('/');
  } catch {
    // TODO
  }
  return hierarchy;
};

export const prepareUpdateBody = ({ hierarchy, roleId, ...values }) => {
  const newHierarchy = hierarchy ? `${hierarchy}/${roleId}` : roleId;

  return { ...values, hierarchy: newHierarchy, roleId };
};

const RoleCreatePage = (props) => {
  const { title, breadcrumbs } = props;
  useBreadcrumbs(breadcrumbs);
  useDocumentTitle(title);
  const { roleId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loading = useSelector(isRoleLoading);
  const roleDetail = useSelector(selectRoleDetails);
  const isUpdating = roleDetail && loading;
  const isLoading = !roleDetail && loading;
  const isNotFound = !roleDetail && !loading;

  const formValue = useMemo(() => {
    if (roleDetail) {
      const { rightIds: rights, hierarchy: parentRole, ...role } = roleDetail;
      const hierarchy = getRealParentRole(parentRole, roleId);

      return {
        ...role,
        hierarchy,
        rights,
      };
    }
    return undefined;
  }, [roleDetail, roleId]);

  const handleUpdateRole = useCallback(
    (values) => {
      const bodyParams = prepareUpdateBody({ ...values, roleId });
      dispatch(updateRoleDetail(bodyParams));
    },
    [dispatch, roleId]
  );

  const handleCancel = useCallback(() => {
    history.push(linkGenerator(`/roles-management/${roleId}`));
  }, [history, roleId]);

  if (isNotFound) {
    return <Redirect to={linkGenerator(`/roles-management/${roleId}`)} />;
  }

  if (isLoading) {
    return (
      <B2becInfoLayout>
        <B2becInfoLayout.Title title={t('pageTitle.editRole')} />
        <B2becInfoLayout.Content>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </B2becInfoLayout.Content>
      </B2becInfoLayout>
    );
  }

  return (
    <RoleForm
      handleSubmit={handleUpdateRole}
      handleCancel={handleCancel}
      initialValues={formValue}
      disabled={isUpdating}
    />
  );
};

RoleCreatePage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default React.memo(RoleCreatePage);
