import './styles.scss';

import { Col, Row } from 'antd';
import { ChevronLeftIcon, ListViewIcon, TileViewIcon } from 'components/Icons';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import { PAGE_TYPES, TRACK_DATA } from 'libs/constants/adobeAnalytics';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  selectAllResultAmount,
  selectAllResultList,
  selectIsAllResultLoading,
} from 'store/selectors/searchSelector';
import { trackOnSiteSearch } from 'store/slices/adobeAnalyticSlice';
import { addProductToCart } from 'store/slices/cartSlice';
import { requestSearch } from 'store/slices/searchSlice';

import B2becPagination from '../../../components/B2becPagination';
import B2becSorting from '../../../components/B2becSorting';
import CustomButton from '../../../components/CustomButton';
import B2becCardSkeleton from '../../../components/Skeletons/B2becCardSkeleton';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import {
  ALL_SEARCH_RESULTS_PER_TYPE,
  CATEGORY_TYPE,
} from '../../../libs/constants';
import { DEFAULT_LANGUAGES } from '../../../libs/constants/lang';
import { GridView, ListView } from '../components/ResultViewList';

function SearchResultsDetailPage(props) {
  const { searchParam, type } = useParams();

  const { title, breadcrumbs, pageId } = props;
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs, [{ pathData: [type, searchParam] }]);

  const { setPageInfoData } = useAdobeAnalysis(TRACK_DATA.ONSITE);

  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const languageCode =
    localStorage.getItem('lng') || DEFAULT_LANGUAGES[0].value;

  const [showListView, setShowListView] = useState(false);

  const getTypeKeyByName = (typeName) => {
    // value of each category in CATEGORY_TYPE is an array.
    // Ex: [accessory, accessories]
    return Object.keys(CATEGORY_TYPE).find(
      (typeKey) => CATEGORY_TYPE[typeKey][1] === typeName
    );
  };

  const { isProductLoading, productsList, productAllAmount } = useSelector(
    (state) => ({
      isProductLoading: selectIsAllResultLoading(state),
      productsList: selectAllResultList(state),
      productAllAmount: selectAllResultAmount(state),
    })
  );

  const onAddProductToCart = useCallback(
    (materialNumber) => {
      dispatch(addProductToCart({ materialNumber }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (searchParam && type) {
      dispatch(
        requestSearch({
          searchAll: true,
          value: searchParam,
          size: ALL_SEARCH_RESULTS_PER_TYPE,
          from: ALL_SEARCH_RESULTS_PER_TYPE * (currentPage - 1),
          lang: languageCode,
          category: getTypeKeyByName(type),
        })
      );

      setPageInfoData({
        pageName: `${type}SearchResults`,
        pageType: PAGE_TYPES.SEARCH_RESULTS,
        pageId,
      });
      dispatch(
        trackOnSiteSearch({
          onsiteSearchTerm: searchParam,
          onsiteSearchResult: productAllAmount,
        })
      );
    }
  }, [
    dispatch,
    searchParam,
    productAllAmount,
    currentPage,
    languageCode,
    type,
    pageId,
    setPageInfoData,
  ]);

  const onChangePaginateHandler = (newPageNumber) => {
    window.scrollTo(0, 0);
    setCurrentPage(newPageNumber);
  };

  return isProductLoading && !showListView ? (
    <B2becCardSkeleton
      gutter={20}
      columns={{ xs: 24, sm: 24, md: 12, lg: 8, xl: 8, xxl: 8 }}
    />
  ) : (
    <div className="container-full-width-page">
      <div className="search-results">
        <div className="search-results__header">
          <div className="search-results__header__title">
            {t('searchResultsPage.title', {
              count: productAllAmount,
            }).trim()}{' '}
            <q>{searchParam}</q> {t(`searchResultsPage.header.${type}`)}
          </div>

          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={18}>
              <CustomButton
                onClick={() => {
                  history.push(linkGenerator(`/search-results/${searchParam}`));
                }}
                className="back-btn"
                buttonSize="small"
              >
                <ChevronLeftIcon />
                {t('searchResultsPage.button.backToOverview')}
              </CustomButton>
            </Col>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={6}
              style={{ textAlign: 'right' }}
            >
              <B2becSorting />
              <span className="change-view-icons">
                <TileViewIcon onClick={() => setShowListView(false)} />
                <ListViewIcon onClick={() => setShowListView(true)} />
              </span>
            </Col>
          </Row>
        </div>
        <div className="search-results__content">
          {showListView ? (
            <ListView
              dataSrc={productsList}
              isLoading={isProductLoading}
              onAddProductToCart={onAddProductToCart}
            />
          ) : (
            <GridView
              productsList={productsList}
              onAddProductToCart={onAddProductToCart}
              dispatch={dispatch}
            />
          )}

          <div className="page-pagination">
            <B2becPagination
              total={productAllAmount}
              current={currentPage}
              pageSize={ALL_SEARCH_RESULTS_PER_TYPE}
              onPageChange={(e) => onChangePaginateHandler(e)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

SearchResultsDetailPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default SearchResultsDetailPage;
