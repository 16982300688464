import './styles.scss';

import { Col, Row } from 'antd';
import { ChevronLeftIcon, ListViewIcon, TileViewIcon } from 'components/Icons';
import NotFound from 'components/NotFound';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import { PAGE_TYPES, TRACK_DATA } from 'libs/constants/adobeAnalytics';
import { getCategories } from 'libs/utils/adobeAnalysis';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { trackCategory } from 'store/slices/adobeAnalyticSlice';
import {
  getProductsByClassType,
  resetProductOverviewData,
  resetProductSubCategoryOverviewData,
} from 'store/slices/categorySlice';

import B2becPagination from '../../../components/B2becPagination';
import B2becSorting from '../../../components/B2becSorting';
import { getSortOption } from '../../../components/B2becSorting/sortOptions';
import CustomButton from '../../../components/CustomButton';
import B2becCardSkeleton from '../../../components/Skeletons/B2becCardSkeleton';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { ACTION_STATUS } from '../../../libs/constants';
import { isValidArray } from '../../../libs/utils/array';
import {
  getProductOverviewData,
  selectSubCategoryOverviewData,
} from '../../../store/selectors/categorySelector';
import DescriptionBox from './components/DescriptionBox';
import FilterBar from './components/FilterBar';
import ProductListContainer from './components/ProductListContainer';
import SubCategoryCarousel from './components/SubCategoryCarousel';

const SubCategory = (props) => {
  const { title, breadcrumbs, pageId, categoryId, subCategoryId, classTypeId } =
    props;
  const { setTitle, setIsTitleTranslated } = useDocumentTitle(title);
  const [setBreadcrumbsData] = useBreadcrumbs(breadcrumbs);
  const { isIpadLandscape } = useDeviceDetect();

  const { setPageInfoData } = useAdobeAnalysis(TRACK_DATA.CATEGORIES);

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [showListView, setShowListView] = useState(false);
  const [sortOption, setSortOption] = useState(
    categoryId === subCategoryId
      ? getSortOption('name.desc')
      : getSortOption('class.desc')
  );
  const { subCategoryOverviewData, productOverviewData } = useSelector(
    (state) => ({
      subCategoryOverviewData: selectSubCategoryOverviewData(state),
      productOverviewData: getProductOverviewData(state),
    })
  );

  const { categoryName: parentCategoryName } = subCategoryOverviewData;
  const {
    classTypes,
    productCount,
    products,
    isLoading,
    pagination,
    actionStatus,
    images,
    text,
    categoryName,
    categoryPath,
  } = productOverviewData;

  const { total } = pagination;

  const productTypeLinkPrefix = useMemo(() => {
    const result = location.pathname.match(/^\/accessories|\/detergents/);
    return result && result.length > 0 ? result[0] : '';
  }, [location.pathname]);

  const allClass = useMemo(
    () => ({
      categoryId: +subCategoryId,
      categoryName: t('configurationManagement.filter.all'),
      productCount,
    }),
    [subCategoryId, t, productCount]
  );

  const filteredClassTypes = useMemo(() => {
    const filterClassTypes = classTypes.filter(
      (classType) => classType?.categoryName && classType?.productCount >= 0
    );

    if (!isIpadLandscape) {
      // In desktop view, return class, insert type 'all' also
      // In mobile view, return only classes
      filterClassTypes.unshift(allClass);
    }

    return filterClassTypes;
  }, [classTypes, allClass, isIpadLandscape]);

  const handleFilterByClassType = useCallback(
    (classTypeID) => {
      setCurrentPage(1);
      if (+subCategoryId === classTypeID) {
        setSortOption(getSortOption('class.desc'));
        history.push(
          linkGenerator(
            `${productTypeLinkPrefix}/category/${categoryId}/subcategory/${subCategoryId}`
          )
        );
      } else {
        history.push(
          linkGenerator(
            `${productTypeLinkPrefix}/category/${categoryId}/subcategory/${subCategoryId}/class/${classTypeID}`
          )
        );
      }
    },
    [categoryId, history, productTypeLinkPrefix, subCategoryId]
  );

  const handlePageChange = useCallback((nextPage) => {
    window.scrollTo(0, 0);
    setCurrentPage(nextPage);
  }, []);

  const handleSort = useCallback((order) => {
    setSortOption(order);
  }, []);

  const handleBackToCategory = useCallback(() => {
    return categoryId === subCategoryId
      ? history.push(linkGenerator(`/categories${productTypeLinkPrefix}`))
      : history.push(
          linkGenerator(`${productTypeLinkPrefix}/category/${categoryId}`)
        );
  }, [history, productTypeLinkPrefix, categoryId, subCategoryId]);

  useEffect(() => {
    let breadcrumbsData = [];
    if (parentCategoryName && categoryName) {
      breadcrumbsData.push({
        titleData: [categoryName],
        pathData: [subCategoryId],
      });
      if (parentCategoryName === categoryName) {
        breadcrumbsData.unshift({});
      } else {
        breadcrumbsData.unshift({
          titleData: [parentCategoryName],
          pathData: [categoryId],
        });
      }
    } else if (parentCategoryName) {
      breadcrumbsData = [
        {
          titleData: [parentCategoryName],
          pathData: [categoryId],
        },
      ];
    } else if (categoryName) {
      breadcrumbsData = [
        {},
        {
          titleData: [categoryName],
          pathData: [subCategoryId],
        },
      ];
    }
    const type = productTypeLinkPrefix.replace('/', '');
    if (type !== '') {
      breadcrumbsData.unshift({
        titleData: [t(`breadcrumbTitle.${type}`)],
        pathData: [type],
      });
      if (breadcrumbsData[1]) {
        breadcrumbsData[1].pathData = [type, categoryId];
      }
      if (breadcrumbsData[2]) {
        breadcrumbsData[2].pathData = [type, categoryId, subCategoryId];
      }
    }
    setBreadcrumbsData(breadcrumbsData);
    setIsTitleTranslated(true);
    setTitle(categoryName);
  }, [
    t,
    setTitle,
    setIsTitleTranslated,
    productTypeLinkPrefix,
    setBreadcrumbsData,
    parentCategoryName,
    categoryName,
    categoryId,
    subCategoryId,
  ]);

  useEffect(() => {
    if (categoryName) {
      let categoryType = 'all';
      if (
        isValidArray(classTypes) &&
        classTypes.some((category) => category?.categoryName === categoryName)
      ) {
        categoryType = categoryName;
      }

      setPageInfoData({
        pageId,
        pageName: `sub categories:${categoryType}`,
        pageType: PAGE_TYPES.SUB_CATEGORIES,
      });
    }
  }, [setPageInfoData, categoryName, classTypes, pageId]);

  useEffect(() => {
    if (!isLoading && actionStatus !== ACTION_STATUS.DEFAULT) {
      dispatch(trackCategory(getCategories(categoryPath, categoryId)));
    }
  }, [dispatch, isLoading, actionStatus, categoryPath, categoryId]);

  useEffect(() => {
    dispatch(
      getProductsByClassType({
        categoryId: classTypeId ?? subCategoryId,
        pageNumber: currentPage,
        pageSize: 12,
        sortOrder: sortOption.sortOrder,
        onlyProduct: !!classTypeId,
      })
    );
  }, [dispatch, subCategoryId, sortOption, classTypeId, currentPage]);

  useEffect(
    () => () => {
      dispatch(resetProductSubCategoryOverviewData());
      dispatch(resetProductOverviewData());
    },
    [dispatch]
  );

  return actionStatus === ACTION_STATUS.FAILED ? (
    <NotFound />
  ) : (
    <div className="sub-category">
      <div className="sub-category__header">
        {isValidArray(images) && (
          <SubCategoryCarousel images={images} isLoading={isLoading} />
        )}
        <DescriptionBox
          categoryName={categoryName}
          text={text}
          isLoading={isLoading}
        />
      </div>

      {/* Show Category Button Area */}
      <div className="full-width-page-component__wrapper">
        <div className="sub-category-back-to-category__wrapper">
          <Row
            className="sub-category-back-to-category__content"
            style={{ marginBottom: filteredClassTypes?.length === 0 && '0' }}
            justify="space-between"
          >
            <Col hidden={isIpadLandscape}>
              <CustomButton
                onClick={handleBackToCategory}
                buttonSize="small"
                className="back-btn"
              >
                <ChevronLeftIcon />
                {t('subCategory.button.backToCategory')}
              </CustomButton>
            </Col>
            <Col>
              <B2becSorting onSort={handleSort} sortOption={sortOption} />
              <span hidden={isIpadLandscape} className="change-view-icons">
                <TileViewIcon onClick={() => setShowListView(false)} />
                <ListViewIcon onClick={() => setShowListView(true)} />
              </span>
            </Col>
          </Row>

          <FilterBar
            onFilter={handleFilterByClassType}
            classTypes={filteredClassTypes}
            hasClassTypeId={classTypeId}
            isIpadLandscape={isIpadLandscape}
            allClass={allClass}
          />
        </div>
      </div>

      {isLoading && !showListView ? (
        <B2becCardSkeleton number={8} />
      ) : (
        <ProductListContainer
          dataSrc={products}
          isListView={showListView}
          isLoading={isLoading}
        />
      )}

      <B2becPagination
        current={currentPage}
        total={total}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

SubCategory.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
  categoryId: PropTypes.string,
  subCategoryId: PropTypes.string,
  classTypeId: PropTypes.string,
};

SubCategory.defaultProps = {
  categoryId: undefined,
  subCategoryId: undefined,
  classTypeId: undefined,
};

export default SubCategory;
