import environment from 'environments/environment.dev';

import HttpClient from "../apiHttpClient";

const TECHINCAL_USER = '/v1.0/technicaluser';

const fapiClient = new HttpClient(environment.adminFAPIUrl);

const createNewTechnicalUser = (body) => {
  return fapiClient.post(`${TECHINCAL_USER}/create`, {
    body,
  });
};

const getUserTechnicalById = (userId, cancelToken) => {
  return fapiClient.get(`${TECHINCAL_USER}/${userId}`, {
    cancelToken,
  });
};

const updateTechnical = (updatedData) => {
  return fapiClient.put(`${TECHINCAL_USER}/update`, {
    body: updatedData,
  });
};

const deleteUserTechnical = (userId) => {
  return fapiClient.delete(`${TECHINCAL_USER}/delete?userId=${userId}`);
};

const setTechnicalUserPassword = ({ userId, username, password }) => {
  return fapiClient.post(`${TECHINCAL_USER}/update-password`, {
    body: { userId, userName: username, newPassword: password },
  });
};

export const adminFapiTechnicalUser = {
  createNewTechnicalUser,
  getUserTechnicalById,
  updateTechnical,
  deleteUserTechnical,
  setTechnicalUserPassword,
};
