import B2becCustomSelect from 'components/B2becCustomSelect';
import { useAsync } from 'hooks';
import { ASYNC_STATUS } from 'libs/constants';
import { CONFIG_DATA_TYPES, CONFIG_NAMES } from 'libs/constants/configs';
import React, { useMemo } from 'react';
import { fapiConfigurationService } from 'services/fapi';

const getListSalesOrg = async () => {
  const response = await fapiConfigurationService.getConfigsByNames([
    {
      configurationKey: CONFIG_NAMES.AVAILABLE_SALESORG,
      dataTypeDto: CONFIG_DATA_TYPES.ARRAY,
    },
  ]);
  return JSON.parse(response[CONFIG_NAMES.AVAILABLE_SALESORG]);
};

const B2bSelectSalesOrg = (props) => {
  const { placeHolder, ...selectProps } = props;
  const { value = [], status } = useAsync(getListSalesOrg, true);

  const options = useMemo(() => {
    if (!value) {
      return [{ value: '', label: placeHolder }];
    }
    const formatedOtions = value?.map((salesOrg) => {
      return {
        label: salesOrg?.name,
        value: salesOrg?.code,
      };
    });

    if (placeHolder) {
      return [{ value: '', label: placeHolder }, ...formatedOtions];
    }

    return formatedOtions;
  }, [placeHolder, value]);

  return (
    <B2becCustomSelect
      options={options}
      loading={status === ASYNC_STATUS.PENDING}
      {...selectProps}
    />
  );
};

export default B2bSelectSalesOrg;
