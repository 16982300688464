import './styles.scss';

import { Col, Row } from 'antd';
import { B2becGuideBlock } from 'components/B2becOverviewGuideBlock';
import {
  ConfigurationsYellowIcon,
  ContactReasonsManagementIcon,
  PromotionsManagementIcon,
  RentalManagementIcon,
  RolesManagementIcon,
  ServiceCaseManagementIcon,
  TranslationsManagementIcon,
  UserManagementIcon,
  VouchersManagementIcon,
} from 'components/Icons';
import PermissionWrapper from 'HOCs/permissionWrapper';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { PHRASE_PROJECT_URL } from 'libs/constants/lang';
import {
  MODULE_ADMINISTRATION_RIGHTS,
  MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS,
} from 'libs/constants/modulerights';
import PropTypes from 'prop-types';
import React from 'react';

const MyWorkspace = (props) => {
  const { title } = props;

  useDocumentTitle(title);

  return (
    <div className="my-workspace__wrapper admin-workspace">
      <div className="container-full-width-page my-workspace__my-machines__wrapper">
        <div className="my-workspace__my-machines__wrapper__inner admin-workspace">
          <Row gutter={[24, { xs: 24, lg: 40 }]} className="overview-guide">
            <Col span={24} lg={8}>
              <B2becGuideBlock
                Icon={<TranslationsManagementIcon />}
                type="translation"
                link={PHRASE_PROJECT_URL}
                isExternalLink
              />
            </Col>
            <PermissionWrapper
              permission={
                MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS.VIEW_VOUCHER
              }
            >
              <Col span={24} lg={8}>
                <B2becGuideBlock
                  Icon={<VouchersManagementIcon />}
                  type="voucher"
                  link="/voucher-management"
                />
              </Col>
            </PermissionWrapper>
            <PermissionWrapper
              permission={
                MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS.UPDATE_PROMOTION
              }
            >
              <Col span={24} lg={8}>
                <B2becGuideBlock
                  Icon={<PromotionsManagementIcon />}
                  type="promotion"
                  link="/promotion-management"
                />
              </Col>
            </PermissionWrapper>
            <PermissionWrapper
              permission={MODULE_ADMINISTRATION_RIGHTS.CRUD_CONTACT_REASON}
            >
              <Col span={24} lg={{ span: 8 }}>
                <B2becGuideBlock
                  Icon={<ContactReasonsManagementIcon />}
                  type="contactReasons"
                  link="/contact-reasons"
                />
              </Col>
            </PermissionWrapper>
            <Col span={24} lg={{ span: 8 }}>
              <B2becGuideBlock
                Icon={<UserManagementIcon />}
                type="userManagement"
                link="/user-management"
              />
            </Col>
            <Col span={24} lg={{ span: 8 }}>
              <B2becGuideBlock
                Icon={<RentalManagementIcon />}
                type="rentalManagement"
                link="/rental-management"
              />
            </Col>
            <PermissionWrapper
              permission={MODULE_ADMINISTRATION_RIGHTS.VIEW_CONFIGURATION}
            >
              <Col span={24} lg={{ span: 8 }}>
                <B2becGuideBlock
                  Icon={<ConfigurationsYellowIcon />}
                  type="configurations"
                  link="/configuration-management"
                />
              </Col>
            </PermissionWrapper>
            <Col span={24} lg={{ span: 8 }}>
              <B2becGuideBlock
                Icon={<ServiceCaseManagementIcon />}
                type="serviceCase"
                link="/service-management"
              />
            </Col>
            <PermissionWrapper
              permission={MODULE_ADMINISTRATION_RIGHTS.ROLE_RIGHT}
            >
              <Col span={24} lg={{ span: 8 }}>
                <B2becGuideBlock
                  Icon={<RolesManagementIcon />}
                  type="roleManagement"
                  link="/roles-management"
                />
              </Col>
            </PermissionWrapper>
            <PermissionWrapper
              permission={
                MODULE_ADMINISTRATION_RIGHTS.VIEW_PRODUCT_PORFOLIO_MANAGEMENT
              }
            >
              <Col span={24} lg={{ span: 8 }}>
                <B2becGuideBlock
                  Icon={<ConfigurationsYellowIcon />}
                  type="productPortfolio"
                  link="/product-management"
                />
              </Col>
            </PermissionWrapper>
            <PermissionWrapper
              permission={MODULE_ADMINISTRATION_RIGHTS.SUPPORT_AREA}
            >
              <Col span={24} lg={{ span: 8 }}>
                <B2becGuideBlock
                  Icon={<ConfigurationsYellowIcon />}
                  type="supportArea"
                  link="/support-area"
                />
              </Col>
            </PermissionWrapper>
          </Row>
        </div>
      </div>
    </div>
  );
};

MyWorkspace.propTypes = {
  title: PropTypes.string.isRequired,
};

export default MyWorkspace;
