import { Radio } from 'antd';
import B2bCustomModal from 'components/B2bCustomModal';
import B2becAddressForm from 'components/B2becAddressForm';
import { DeliveryAddress } from 'components/DisplayAddress';
import SearchAddress from 'pages/CheckoutPage/StepOneComponent/SearchAddress';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getDeliveryAddresses } from 'store/selectors/customerSelector';
import { selectUserCountry } from 'store/selectors/userSelector';

const SelectAddressMobile = (props) => {
  const { value, onChange, isModalVisible, onCancel } = props;
  const { t } = useTranslation();
  const deliveryAddresses = useSelector(getDeliveryAddresses);
  const country = useSelector(selectUserCountry);
  const [isSearchAddress, setIsSearchAddress] = useState(false);
  const selectedPartnerNumber = value?.partnerNumber;
  const isAddNewAddress = !selectedPartnerNumber;

  const first3Addresses = useMemo(() => {
    if (Array.isArray(deliveryAddresses)) {
      if (isSearchAddress) {
        return deliveryAddresses.filter(
          ({ partnerNumber }) => partnerNumber === selectedPartnerNumber
        );
      }
      return deliveryAddresses.slice(0, 3);
    }

    return [];
  }, [deliveryAddresses, isSearchAddress, selectedPartnerNumber]);

  const onChangeValue = useCallback(
    (data) => {
      onChange({ ...data, isAddNew: !isAddNewAddress });
    },
    [onChange, isAddNewAddress]
  );

  const handleAddNewAddress = useCallback(
    (values) => {
      if (!values.isShow) {
        onChangeValue({});
      }
    },
    [onChangeValue]
  );

  const handleCancel = useCallback(() => {
    setIsSearchAddress(true);
    onCancel();
  }, [onCancel]);

  return (
    <div className="custom-input">
      {first3Addresses.map((data) => {
        return (
          <Radio
            key={data.partnerNumber}
            className="delivery-information-radio border-bottom"
            onClick={() => onChangeValue(data)}
            checked={data.partnerNumber === selectedPartnerNumber}
          >
            <DeliveryAddress data={data} />
          </Radio>
        );
      })}
      <Radio
        className="delivery-information-radio border-bottom"
        checked={isAddNewAddress}
        onClick={() => onChangeValue({ country })}
      >
        <span className="mt-6 mb-4 delivery__option__title">
          {t('form.label.newAddress')}
        </span>
      </Radio>

      {isAddNewAddress && (
        <B2becAddressForm id="deliveryAddress" onChange={handleAddNewAddress} />
      )}

      <B2bCustomModal visible={isModalVisible} isFullScreen>
        <SearchAddress
          dataSource={deliveryAddresses}
          value={value}
          onChange={onChangeValue}
          onCancel={handleCancel}
        />
      </B2bCustomModal>
    </div>
  );
};

export default SelectAddressMobile;

SelectAddressMobile.propTypes = {
  value: PropTypes.shape({ partnerNumber: PropTypes.string }),
  isModalVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
};

SelectAddressMobile.defaultProps = {
  value: {
    partnerNumber: '',
  },
  isModalVisible: false,
  onCancel: () => {},
  onChange: () => {},
};
