import './styles.scss';

import { Dropdown, Menu } from 'antd';
import { ChevronDownIcon, ChevronUpIcon } from 'components/Icons';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { defaultSortOptions, getSortOption } from './sortOptions';

const B2becSorting = ({ options, sortOption, onSort }) => {
  const { t } = useTranslation();
  const [selectedKey, setSelectedKey] = useState(
    sortOption?.sortOrder !== 0 ? sortOption?.key : null
  );

  const handleSort = useCallback(
    ({ key }) => {
      const sort = getSortOption(key);

      setSelectedKey(sort.key);
      onSort(sort);
    },
    [onSort]
  );

  const menu = (
    <Menu
      onClick={handleSort}
      selectedKeys={[`${selectedKey}`]}
      className="b2bec-sorting__menu"
    >
      {options.map(({ key, text, sortOrder }) =>
        sortOrder !== 0 ? <Menu.Item key={key}>{t(text)}</Menu.Item> : null
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} className="b2bec-sorting__wrapper">
      <button
        type="button"
        onClick={(e) => e.preventDefault()}
        className="b2bec-sorting__button"
      >
        <div className="b2bec-sorting__icons-wrapper">
          <ChevronUpIcon />
          <ChevronDownIcon />
        </div>
        <span>{t('sorting.button.sort')}</span>
      </button>
    </Dropdown>
  );
};

export default B2becSorting;

B2becSorting.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  sortOption: PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.string,
    sortOrder: PropTypes.number,
  }),
  onSort: PropTypes.func,
};

B2becSorting.defaultProps = {
  options: defaultSortOptions,
  sortOption: {},
  onSort: () => {},
};
