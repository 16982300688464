import './styles.scss';

import { Col, Form, Row } from 'antd';
import CustomButton from 'components/CustomButton';
import { NotificationManager } from 'components/Notification';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useAsync from 'hooks/useAsync';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { ADDRESS_ACTION_TYPES } from 'libs/constants/actionTypes';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { RESPONSE_STATUS } from 'libs/constants/statuses';
import { USER_TYPES } from 'libs/constants/user';
import { linkGenerator } from 'libs/utils/language';
import { isFormDirty } from 'libs/utils/validatorUtils';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fapiCustomerService } from 'services/fapi';
import {
  selectUserEmail,
  selectUserId,
  selectUserType,
} from 'store/selectors/authenticationSelectors';
import { selectProvinces } from 'store/selectors/configSelector';
import {
  getBillingAddress,
  getCustomerAddress,
  getCustomerAddressPartnerNumber,
  getDeliveryAddresses,
} from 'store/selectors/customerSelector';
import {
  selectUserCountry,
  selectUserCountryId,
  selectUsername,
} from 'store/selectors/userSelector';
import { userActions } from 'store/slices/userSlice';

import PermissionWrapper from '../../HOCs/permissionWrapper';
import { MODULE_PROFILE_RIGHTS } from '../../libs/constants/modulerights';
import AddressDetailBlock from './components/AddressDetailBlock';
import DeliveryAddressBlock from './components/DeliveryAddressBlock';
import InformationBlock from './components/InformationBlock';
import InvoiceAddress from './components/InvoiceAddress';
import LanguageBlock from './components/LanguageBlock';
import PasswordBlock from './components/PasswordBlock';

const renderZipCodeErrorPopup = (responseError) => {
  // eslint-disable-next-line radix
  const errorCode = parseInt(responseError?.response?.data.errorCode);
  if (errorCode === RESPONSE_STATUS.invalidZip) {
    return NotificationManager.error({
      message: 'checkout.submitErrors.invalidZip',
    });
  }

  return NotificationManager.error({
    message: 'notification.error.addChangeAddress',
  });
};

export default function ProfilePage(props) {
  const { title, breadcrumbs, pageId } = props;
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);

  useAdobeAnalysis(null, {
    pageId,
    pageName: 'my profile',
    pageType: PAGE_TYPES.PROFILE,
  });

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [isAddressDetailBlockShown, setIsAddressDetailBlockShown] =
    useState(false);
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState({});
  const [actionType, setActionType] = useState(null);

  const userId = useSelector(selectUserId);
  const deliveryAddresses = useSelector(getDeliveryAddresses);
  const customerAddress = useSelector(getCustomerAddress);
  const billingAddress = useSelector(getBillingAddress);
  const partnerNumber = useSelector(getCustomerAddressPartnerNumber);
  const email = useSelector(selectUserEmail);
  const username = useSelector(selectUsername);
  const country = useSelector(selectUserCountry);
  const countryId = useSelector(selectUserCountryId);
  const userType = useSelector(selectUserType);
  const provinces = useSelector(selectProvinces);

  const resetAddressDetailBlock = useCallback(() => {
    form.setFieldsValue({
      name: '',
      name2: '',
      street: '',
      street4: '',
      houseNumber: '',
      phoneNumber: '',
      streetAndHouseNumber: '',
      zip: '',
      region: '',
      city: '',
      country,
      isDefaultAddress: '',
      partnerNumber: '',
      taxNumber: '',
    });
  }, [country, form]);

  const cancelRequest = useCallback(() => {
    setIsAddressDetailBlockShown(false);
    setActionType(null);
    form.setFieldsValue({
      deliveryAddress: 0,
    });
    resetAddressDetailBlock();
    window.scrollTo({ top: 0 });
  }, [form, resetAddressDetailBlock]);

  const submitChangeDeliveryAddress = useCallback((params) => {
    return fapiCustomerService.changeDeliveryAddress(params);
  }, []);

  const submitAddDeliveryAddress = useCallback((params) => {
    return fapiCustomerService.addDeliveryAddress(params);
  }, []);

  const submitDeleteDeliveryAddress = (params) => {
    return fapiCustomerService.deleteDeliveryAddress(params);
  };

  const {
    execute: executeChangeDeliveryAddress,
    status: changeDeliveryAddressStatus,
    error: changeDeliveryAddressError,
  } = useAsync(submitChangeDeliveryAddress, false);
  const {
    execute: executeAddDeliveryAddress,
    status: addDeliveryAddressStatus,
    error: addDeliveryAddressError,
  } = useAsync(submitAddDeliveryAddress, false);
  const {
    execute: executeDeleteDeliveryAddress,
    status: deleteDeliveryAddressStatus,
    error: deleteDeliveryAddressError,
  } = useAsync(submitDeleteDeliveryAddress, false);

  const customerName = useMemo(
    () => `${customerAddress?.name || ''} ${customerAddress?.name2 || ''}`,
    [customerAddress]
  );

  const customerInfo = useMemo(
    () => ({
      ...customerAddress,
      name: customerName,
    }),
    [customerAddress, customerName]
  );

  const billingInfo = useMemo(
    () => ({
      ...billingAddress,
      names: [
        billingAddress?.name,
        billingAddress?.name2,
        billingAddress?.name3,
      ],
    }),
    [billingAddress]
  );

  const isAddressDetailLoading =
    changeDeliveryAddressStatus === 'pending' ||
    addDeliveryAddressStatus === 'pending';

  useEffect(() => {
    if (changeDeliveryAddressStatus === 'success') {
      setIsAddressDetailBlockShown(false);
      cancelRequest();

      NotificationManager.success({
        message: 'notification.success.addChangeAddress',
      });

      return;
    }

    if (changeDeliveryAddressStatus === 'error' && changeDeliveryAddressError) {
      renderZipCodeErrorPopup(changeDeliveryAddressError);
    }
  }, [changeDeliveryAddressStatus, changeDeliveryAddressError, cancelRequest]);

  useEffect(() => {
    if (addDeliveryAddressStatus === 'success') {
      cancelRequest();
      setIsAddressDetailBlockShown(false);

      NotificationManager.success({
        message: 'notification.success.addChangeAddress',
      });

      return;
    }

    if (addDeliveryAddressStatus === 'error' && addDeliveryAddressError) {
      renderZipCodeErrorPopup(addDeliveryAddressError);
    }
  }, [addDeliveryAddressStatus, addDeliveryAddressError, cancelRequest]);

  useEffect(() => {
    if (deleteDeliveryAddressStatus === 'success') {
      cancelRequest();

      NotificationManager.success({
        message: 'notification.success.addChangeAddress',
      });

      return;
    }

    if (deleteDeliveryAddressError) {
      NotificationManager.error({
        message: 'notification.error.addChangeAddress',
      });
    }
  }, [deleteDeliveryAddressStatus, deleteDeliveryAddressError, cancelRequest]);

  const handleDeleteAddress = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue(false);
      if (values.region && provinces[values.region]) {
        values.region = provinces[values.region].label;
      }
      executeDeleteDeliveryAddress(values);
    } catch (err) {
      console.error('handleDeleteAddress: err', err);
    }
  };

  const handleAddAddress = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue(false);
      const bodyRequest = {
        name: values.name,
        name2: values.name2,
        street: values.street,
        street4: values.street4,
        houseNumber: values.houseNumber,
        city: values.city,
        country: values.country,
        zip: values.zip,
        region: values.region,
        phoneNumber: customerAddress.phoneNumber,
        taxNumber: customerAddress.taxNumber,
        isDefaultAddress: values.isDefaultAddress?.length > 0,
      };
      if (bodyRequest.region && provinces[bodyRequest.region]) {
        bodyRequest.region = provinces[bodyRequest.region].label;
      }
      executeAddDeliveryAddress(bodyRequest);
    } catch (err) {
      console.error('handleAddAddress: err', err);
    }
  };

  const handleChangeAddress = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue(false);
      const isDirty = isFormDirty(selectedDeliveryAddress, values);
      if (isDirty) {
        const bodyRequest = {
          name: values.name,
          name2: values.name2,
          street: values.street,
          street4: values.street4,
          houseNumber: values.houseNumber,
          city: values.city,
          country: values.country,
          zip: values.zip,
          region: values.region,
          partnerNumber: values.partnerNumber,
          phoneNumber: values.phoneNumber,
          taxNumber: values.taxNumber,
        };
        if (bodyRequest.region && provinces[bodyRequest.region]) {
          bodyRequest.region = provinces[bodyRequest.region].label;
        }
        executeChangeDeliveryAddress(bodyRequest);
      }
    } catch (err) {
      console.error('handleChangeAddress: err', err);
    }
  };

  const handleDeliveryAddressChange = (selectedIndex) => {
    if (selectedIndex > 0) {
      setIsAddressDetailBlockShown(true);
      setActionType(ADDRESS_ACTION_TYPES.CHANGE_ADDRESS);

      let isDefaultAddress = '';

      if (selectedIndex === 1) {
        isDefaultAddress = 'isDefaultAddress';
      }

      const selectedAddress = deliveryAddresses[selectedIndex - 1];
      const selectedValues = {
        name: selectedAddress.name,
        name2: selectedAddress.name2,
        street: selectedAddress.street,
        street4: selectedAddress.street4,
        houseNumber: selectedAddress.houseNumber,
        zip: selectedAddress.zip,
        region: selectedAddress.region,
        city: selectedAddress.city,
        country: selectedAddress.country,
        partnerNumber: selectedAddress.partnerNumber,
        taxNumber: selectedAddress.taxNumber,
        phoneNumber: selectedAddress.phoneNumber,
        isDefaultAddress,
      };
      form.setFieldsValue(selectedValues);
      setSelectedDeliveryAddress(selectedValues);
    } else {
      resetAddressDetailBlock();
      setIsAddressDetailBlockShown(false);
      setActionType(null);
    }
  };

  const handleChangeLanguage = (newLanguage, initialLangCode) => {
    dispatch(
      userActions.requestChangeLanguage({
        userId,
        newLanguage,
        countryId,
        initialLangCode,
      })
    );
  };

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      validateMessages={{
        required: t('myProfile.form.validateMessages.required'),
      }}
    >
      <div className="profile__wrapper">
        <InformationBlock
          partnerNumber={partnerNumber}
          email={email}
          username={username}
        />
        <Row gutter={[24, 24]}>
          <Col xs={24} lg={12}>
            <DeliveryAddressBlock
              deliveryAddresses={deliveryAddresses}
              customerAddress={customerAddress}
              handleDeliveryAddressChange={handleDeliveryAddressChange}
              customerInfo={customerInfo}
            />
          </Col>
          <Col xs={24} lg={{ span: 8, offset: 4 }}>
            <InvoiceAddress billingInfo={billingInfo} />
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col xs={24} lg={12}>
            <div className="profile__main-block__buttons">
              <PermissionWrapper
                permission={
                  MODULE_PROFILE_RIGHTS.REQUEST_DELIVERY_ADDRESS_CHANGE
                }
              >
                <CustomButton
                  className="btn btn--submit"
                  type="primary"
                  disabled={isAddressDetailBlockShown}
                  onClick={() => {
                    setIsAddressDetailBlockShown(true);
                    setActionType(ADDRESS_ACTION_TYPES.ADD_NEW_ADDRESS);
                    resetAddressDetailBlock();
                  }}
                >
                  {t('myProfile.addANewAddress')}
                </CustomButton>
              </PermissionWrapper>
            </div>
            {isAddressDetailBlockShown ? (
              <AddressDetailBlock
                actionType={actionType}
                cancelRequest={cancelRequest}
                form={form}
                handleDeleteAddress={handleDeleteAddress}
                handleAddAddress={handleAddAddress}
                handleChangeAddress={handleChangeAddress}
                loading={isAddressDetailLoading}
              />
            ) : null}
          </Col>
          <Col xs={24} lg={{ span: 8, offset: 4 }}>
            <Row gutter={[24, { xs: 24, sm: 24, md: 50, lg: 70 }]}>
              <PermissionWrapper
                permission={
                  MODULE_PROFILE_RIGHTS.REQUEST_INVOICE_ADDRESS_CHANGE
                }
              >
                <Col span={24}>
                  <CustomButton
                    className="btn btn--submit"
                    type="primary"
                    onClick={() =>
                      history.push(
                        linkGenerator('/profile/change-invoice-address')
                      )
                    }
                  >
                    {t('myProfile.requestInvoiceChanges')}
                  </CustomButton>
                </Col>
              </PermissionWrapper>
              {userType === USER_TYPES.CUSTOMER_USER ? (
                <PermissionWrapper
                  permission={MODULE_PROFILE_RIGHTS.RESET_OWN_PASSWORD}
                >
                  <Col span={24}>
                    <PasswordBlock />
                  </Col>
                </PermissionWrapper>
              ) : null}
              <Col span={24}>
                <LanguageBlock onChangeLanguage={handleChangeLanguage} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Form>
  );
}

ProfilePage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};
